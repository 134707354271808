import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Container from '@material-ui/core/Container';
import tableI18n from '../TableLocalization';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  getExpenses,
  setExpenseId,
  setExpense,
  deleteExpense,
  atualizarDespesa,
  parseDocx,
} from '../../actions/financial';
import { getBeneficiarios } from '../../actions/beneficiario';
import SindilegisBar from '../commons/SindilegisBar';
import RangeDatePicker from '../../components/commons/RangeDatePicker';
import ConfirmDialog from '../commons/ConfirmDialog';
import ExpenseForm from './expense';
import LiquidacaoPagamento from './liquidacao_pagamento';
import EstornoDespesa from './estorno_despesa';
import FileUpload from '../commons/FileUpload';
import { storage } from '../../firebase';
import * as routes from '../../constants/routes';
import * as roles from '../../constants/roles';
import {
  getDataInicial,
  getDataFinal,
  getCorDespesa,
  formatarValor,
  calcularEstorno,
  formataData,
  dataPorExtenso,
} from './util';
import * as situacoes from './situacoes';
import _ from 'lodash';
import styles from './styles';
import * as numero from 'numero-por-extenso';
import ButtonComponent from '../commons/ButtonComponent';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ListaDespesas = ({
  classes,
  authUser,
  expenses,
  beneficiarios,
  getExpenses,
  getBeneficiarios,
  setExpenseId,
  setExpense,
  deleteExpense,
  atualizarDespesa,
  parseDocx,
  loading
}) => {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(getDataInicial());
  const [toDate, setToDate] = useState(getDataFinal());
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(Function);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isDataParametrizada, setIsDataParametrizada] = useState(false);
  const location = useLocation()

  useEffect(() => {
    const data_final = location?.params?.data_final;
    setIsDataParametrizada(!_.isEmpty(data_final));
    getExpenses();
    getBeneficiarios();
  }, [getExpenses, getBeneficiarios, location.params]);

  const filterExpenses = useCallback((fromDate, toDate) => {
    let filtered = expenses.filter((e) => fromDate <= e.data_vencimento && toDate >= e.data_vencimento);
    if (isDataParametrizada) {
      filtered = filtered.filter((e) => !e.data_liquidacao_pagamento);
    }
    setFilteredExpenses(filtered);
  }, [expenses, isDataParametrizada]);

  useEffect(() => {
    if (expenses) {
      if (isDataParametrizada) {
        const { data_inicial, data_final } = location.params;
        filterExpenses(data_inicial, data_final);
        return;
      }
      filterExpenses(fromDate.getTime(), toDate.getTime());
    }
  }, [expenses, fromDate, toDate, isDataParametrizada, location.params, filterExpenses]);

  const editExpense = (id) => {
    setExpenseId(id)
    setOpen(true)
  }

  const handleOpen = () => {
    setExpenseId(null);
    setExpense(null);
    setOpen(true);
    getExpenses();
  };

  const handleClose = () => {
    setOpen(false);
    getExpenses();
    setExpense(null)
    setExpenseId(null)
  };

  const handleDateChange = (date) => {
    setFromDate(date.fromDate);
    setToDate(date.toDate);
    filterExpenses(date.fromDate.getTime(), date.toDate.getTime());
  };

  const handleConfirmOpen = (action, message) => {
    setConfirmOpen(true);
    setConfirmAction(() => action);
    setConfirmMessage(message);
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      confirmAction();
    }
    setConfirmOpen(false);
  };

  const selecionarDespesa = (event, id) => {
    const updatedExpenses = filteredExpenses.map((expense) =>
      expense.id === id ? { ...expense, selecionado: !expense.selecionado } : expense
    );
    setFilteredExpenses(updatedExpenses);
    event.stopPropagation();
  };

  const solicitarAprovacao = () => {
    filteredExpenses
      .filter((d) => d.selecionado)
      .forEach((d) => {
        atualizarDespesa(d.id, {
          situacao: situacoes.AG_APROVACAO,
          solicitante: authUser.email,
        });
      });
  };

  const deleteAnexo = (despesa, anexo) => {
    const anexos = despesa.anexos

    handleConfirmOpen(() => {
      if (anexos && anexos[anexo.id]) {
        delete anexos[anexo.id]
      }
      atualizarDespesa(despesa.id, { anexos })
      storage.removerAnexo(anexo.url)

    }, `Confirma a exclusão do documento ${anexo.nome}?`)
  }

  const gerarRecibo = (despesa) => {
    const beneficiario = beneficiarios.find((b) => b?.id === despesa?.id_beneficiario);
    parseDocx({
      valor: despesa?.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
      valorPorExtenso: numero?.porExtenso(despesa?.valor, numero?.estilo?.monetario),
      data: dataPorExtenso(new Date(despesa?.data_vencimento)),
      nome: beneficiario?.nome_beneficiario,
      tipoDocumento: beneficiario?.tipo === 'J' ? 'CNPJ' : 'CPF',
      documento: beneficiario?.cpfCnpj,
      descricao: despesa?.descricao,
    });
  };

  const valoresSelecionados = filteredExpenses
    .filter((d) => d.selecionado)
    .map((e) => calcularEstorno(e))
    .reduce((a, b) => a + b, 0);

  const canEdit = authUser.roles.includes(roles.ADMIN_FINANCEIRO);
  const currentPath = location.pathname;
  const buttonsRoutes = getRoutes(routes, currentPath);

  return (
    <React.Fragment>
      <Fab variant="extended" aria-label="delete" className={classes.soma}>
        {formatarValor(valoresSelecionados)}
      </Fab>
      <SindilegisBar />
      <Container maxWidth="xl" className={classes.containerBottomPadding}>
        <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
        {canEdit && (
          <React.Fragment>
            <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {filteredExpenses.filter((d) => d.selecionado).length ? (
                <ButtonComponent
                  disabled={!filteredExpenses.filter((d) => d.selecionado).length}
                  variant="secondary"
                  onClick={solicitarAprovacao}>
                  Solicitar aprovação
                </ButtonComponent>
              ) : (
                <ButtonComponent variant="insert" onClick={handleOpen}>
                  Cadastrar Despesa
                </ButtonComponent>
              )}
            </Grid>
          </React.Fragment>
        )}
        <Grid container justifyContent="space-between" alignItems="center" className={classes.areaOperacoes}>
          <Grid item container spacing={1} md={5} justifyContent="flex-start">
            {!isDataParametrizada && (
              <RangeDatePicker fromDate={fromDate} toDate={toDate} onChange={handleDateChange} />
            )}
          </Grid>
        </Grid>
        <MaterialTable
          columns={[
            {
              title: '',
              field: 'selecionado',
              render: (row) => (
                <Checkbox
                  checked={!!row.selecionado}
                  disabled={row.situacao && row.situacao !== situacoes.NEGADA}
                  onChange={(event) => selecionarDespesa(event, row.id)}
                  onClick={(e) => e.stopPropagation()}
                  inputProps={{ 'aria-label': 'Selecionar despesa' }}
                />
              ),
            },
            {
              title: 'Tipo',
              field: 'tipo_despesa',
              render: (row) => (
                <span style={{ color: getCorDespesa(row.data_vencimento, row.data_liquidacao_pagamento) }}>
                  {row.tipo_despesa}
                </span>
              ),
            },
            { title: 'Item', field: 'item_despesa' },
            { title: 'Beneficiário', field: 'nome_beneficiario' },
            { title: 'Parcela', field: 'parcela' },
            {
              title: 'Valor',
              field: 'valor',
              render: (row) => <span>{formatarValor(row.valor)}</span>,
            },
          ]}
          data={filteredExpenses}
          title="Despesas"
          detailPanel={(row) => renderDetail(row, canEdit)}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          actions={[
            {
              icon: 'cloud_download',
              tooltip: 'Gerar recibo',
              onClick: (event, row) => gerarRecibo(row),
              disabled: !canEdit,
            },
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (event, row) => { editExpense(row.id) },
              disabled: !canEdit,
            },
            (rowData) => {
              if (
                !canEdit ||
                (authUser.email !== 'amanda@sindilegis.org.br' && rowData.data_liquidacao_pagamento)
              ) {
                return;
              }
              return {
                icon: 'delete',
                tooltip: 'Excluir',
                onClick: (event, row) =>
                  handleConfirmOpen(() => deleteExpense(row.id), 'Confirma exclusão da despesa?'),
              };
            },
          ]}
          localization={tableI18n}
        />
        <ConfirmDialog open={confirmOpen} message={confirmMessage} onClose={handleConfirmClose} />
        <ExpenseForm open={open} handleClose={handleClose} />
      </Container>
    </React.Fragment>
  );

  function renderDetail(despesa, canEdit) {
    const {
      descricao,
      numero_parcelas,
      meio_pagamento,
      tipo_despesa,
      centro_custo,
      data_vencimento,
      id,
      anexos,
      data_liquidacao_pagamento,
      aprovadores,
    } = despesa;

    return (
      <Grid container className={classes.despesa}>
        <Grid container spacing={4}>
          <Grid item sm={8}>
            <Typography variant="body2" color="textSecondary">
              Descrição
            </Typography>
            <Typography variant="body1">{descricao}</Typography>
          </Grid>

          <Grid item sm={4}>
            <Typography variant="body2" align="right" color="textPrimary">
              Nº de parcelas: {numero_parcelas} | Meio de pagamento: {meio_pagamento} | {tipo_despesa}
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" color="textSecondary">
              Centro de custo
            </Typography>
            <Typography variant="body1">{centro_custo}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" color="textSecondary">
              Data de vencimento
            </Typography>
            <Typography variant="body1">{formataData(data_vencimento)}</Typography>
          </Grid>
          <Grid item container sm={8} spacing={2} justifyContent="flex-end" alignItems="center">
            <LiquidacaoPagamento despesa={despesa} />
          </Grid>
          <Grid item container sm={12} spacing={2} justifyContent="flex-end" alignItems="center">
            <EstornoDespesa despesa={despesa} />
          </Grid>
          <FileUpload
            id={id}
            anexos={anexos}
            pagamentoLiquidado={!!data_liquidacao_pagamento}
            handleDelete={(anexo) => deleteAnexo(despesa, anexo)}
            canEdit={canEdit}
            canDelete={authUser.email === 'amanda@sindilegis.org.br'}
          />
          {aprovadores && (
            <Grid item container sm={6} spacing={2} justifyContent="flex-end" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Aprovador por:
              </Typography>
              <Typography variant="body1">{aprovadores.join(',')}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = ({ financial, beneficiario: { beneficiarios } }) => ({
  expenses: financial.expenses,
  expenseId: financial.expenseId,
  loading: financial.loading,
  beneficiarios,
});

ListaDespesas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getExpenses,
    setExpenseId,
    setExpense,
    deleteExpense,
    atualizarDespesa,
    parseDocx,
    getBeneficiarios,
  })
)(withRouter(ListaDespesas));
