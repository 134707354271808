import React, { useState, useEffect, useCallback } from 'react';
import { reset } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Container, Fab, Grid, Typography, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { formataData } from '../Financial/util';
import * as routes from '../../constants/routes';
import tableI18n from '../TableLocalization';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { downloadXlsx, getRoutes } from '../commons/util';
import MenuButton from '../commons/MenuButton';
import { withSnackbar } from 'notistack';
import {
  getBensPatrimonio,
  getHistoricoBem,
  removerAnexoBem,
  setBem,
  setBemId,
} from '../../actions/patrimonio';
import BemPatrimonial from './bemPatrimonial';
import _ from 'lodash';
import AnexosBemPatrimonial from './anexos/anexosBemPatrimonial';
import HistoricoDeEdicao from './common/historicoDeEdicao';

const Patrimonio = ({ classes, authUser, location, enqueueSnackbar, removerAnexoBem }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openBem, setOpenBem] = useState(false);
  const [openHistorico, setOpenHistorico] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [historicoBem, setHistoricoBem] = useState([]);
  const [title, setTitle] = useState('');
  const [anexosBem, setAnexosBem] = useState([]);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [openArquivos, setOpenArquivos] = useState(false);
  const { bens, loading } = useSelector((state) => state.patrimonio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBensPatrimonio());
  }, [dispatch]);

  const handleConfirmOpen = useCallback((action, message) => {
    setConfirmOpen(true);
    setConfirmAction(() => action);
    setConfirmMessage(message);
  }, []);

  const handleConfirmClose = useCallback(
    (confirm) => {
      if (confirm) {
        confirmAction();
        setConfirmOpen(false);
      } else {
        setConfirmOpen(false);
      }
    },
    [confirmAction]
  );

  const handleEditBem = (id) => {
    dispatch(setBemId(id));
    setOpenBem(true);
  };

  const handleCloseBem = useCallback(() => {
    dispatch(setBem(null));
    dispatch(setBemId(null));
    setIsCreating(false);
    setOpenBem(false);
    dispatch(getBensPatrimonio());
    reset();
  }, [dispatch]);

  const handleCloseArquivos = () => {
    setOpenArquivos(false);
  };

  const handleDeleteArquivo = (bemId, anexoId) => {
    return new Promise((resolve, reject) => {
      handleConfirmOpen(() => {
        removerAnexoBem(bemId, anexoId)
          .then(() => {
            enqueueSnackbar('Arquivo excluído com sucesso!', { variant: 'success' });
            resolve();
            dispatch(getBensPatrimonio());
          })
          .catch((error) => {
            enqueueSnackbar('Erro ao excluir arquivo!', { variant: 'error' });
            reject(error);
          });
      }, 'Confirma a exclusão do arquivo?');
    });
  };
  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  const handleOpenHistorico = async (row) => {
    setLoadingHistorico(true);
    const historicos = await dispatch(getHistoricoBem(row?.id));
    setLoadingHistorico(false);
    setHistoricoBem(historicos);
    setOpenHistorico(true);
    setTitle(row?.tipo_objeto);
    setAnexosBem(row?.anexos);
  };

  const handleCloseHistorico = () => {
    setOpenHistorico(false);
    setTitle('');
  };

  return (
    <React.Fragment>
      <SindilegisBar />
      <Loading loading={loading} />
      {!loading && (
        <Container maxWidth="xl">
          <MenuButton buttonsRoutes={buttonRoutes} location={location} />
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: 'Nº Patrimônio',
                    field: 'n_patrimonio',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Tipo de Objeto',
                    field: 'tipo_objeto',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Especificação do Objeto',
                    field: 'especificacao',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Nome do Usuário',
                    field: 'usuario',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) => rowData?.usuario?.toUpperCase(),
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Setor Alocado',
                    field: 'setor_alocado',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Data de Cadastro',
                    field: 'data_cadastro',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) => formataData(rowData.data_cadastro),
                  },
                  {
                    title: 'Nº de Série',
                    field: 'n_serie',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Data de Descarte',
                    field: 'data_descarte',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) => formataData(rowData.data_descarte),
                    cellStyle: {},
                  },
                  {
                    title: 'Status',
                    field: 'status',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                ]}
                data={Object.values(bens)}
                title="Patrimônio"
                actions={[
                  (rowData) => {
                    const { id } = rowData;
                    return {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: () => {
                        handleEditBem(id);
                      },
                    };
                  },
                  (rowData) =>
                    rowData.anexos &&
                    rowData.anexos !== null &&
                    Object.values(rowData.anexos).some((anexo) => anexo.situacao !== 'Excluído')
                      ? {
                          icon: 'folder',
                          tooltip: 'Ver anexos',
                          onClick: () => {
                            dispatch(setBemId(rowData.id));
                            setOpenArquivos(true);
                          },
                        }
                      : null,
                  {
                    icon: 'notes',
                    tooltip: 'Histórico de edição',
                    onClick: (event, rowData) => {
                      handleOpenHistorico(rowData);
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  exportCsv: (columns, data) => {
                    data.forEach((d) => {
                      d.data_cadastro = formataData(d.data_cadastro);
                      d.data_descarte = formataData(d.data_descarte);
                    });

                    downloadXlsx(
                      _.filter(columns, (item) => !item.noExport),
                      data,
                      'bens_patrimoniais.xlsx'
                    );
                  },
                }}
                localization={tableI18n}
              />
              <Fab
                color="primary"
                aria-label="Cadastrar"
                className={classes.fab}
                onClick={() => setIsCreating(true) & setOpenBem(true)}>
                <AddIcon />
              </Fab>
            </div>
          </Grid>
          <ConfirmDialog open={confirmOpen} message={confirmMessage} onClose={handleConfirmClose} />
        </Container>
      )}
      <BemPatrimonial
        open={openBem}
        handleClose={handleCloseBem}
        by={authUser?.email}
        isCreating={isCreating}
      />
      <AnexosBemPatrimonial
        open={openArquivos}
        handleClose={handleCloseArquivos}
        handleDeleteArquivoBem={handleDeleteArquivo}
      />
      <HistoricoDeEdicao
        open={openHistorico}
        onClose={handleCloseHistorico}
        title={title}
        historico={historicoBem}
        loadingHistorico={loadingHistorico}
        anexos={anexosBem}
      />
    </React.Fragment>
  );
};

Patrimonio.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { removerAnexoBem })(
  withStyles(styles)(withSnackbar(withRouter(Patrimonio)))
);
