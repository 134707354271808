import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { formataData } from '../../commons/util';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableI18n from '../../TableLocalization';
import { getCorDespesa, formatarValor, calcularEstorno, somadorDouble } from '../../Financial/util';
import * as roles from '../../../constants/roles';
import _ from 'lodash';
import FileUpload from '../../commons/FileUpload';
import FileUploadReceita from '../../commons/FileUploadReceita';
import { downloadXlsx } from '../../commons/util';

const getLabel = (key) => {
  const labels = {
    nome: 'Nome',
    tipo_objeto: 'Tipo do objeto',
    cnpj_contratados: 'Cnpj dos contratados',
    data_inicio: 'Início vigência',
    data_fim: 'Fim vigência',
    data_alerta: 'Data para alerta',
    emails: 'E-mails para alerta',
    observacoes: 'Observações',
    situacao: 'Situação',
    data_carregamento: 'Data de carregamento',
    numero_contrato: 'Número do contrato',
  };
  return labels[key] || key;
};

const Detalhamento = ({
  open,
  onClose,
  observacoes,
  historico,
  title,
  receitas,
  despesas,
  authUser,
  isDetalhamento,
  loadingFinancial,
  loadingHistorico,
  anexos,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const canEdit = _.includes(authUser.roles, roles.GESTOR_CONTRATOS);
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };
  const tabLabels = isDetalhamento
    ? ['Receitas', 'Despesas']
    : ['Observações', 'Histórico de Alterações'];

  const localization = tableI18n;
  localization.toolbar.nRowsSelected = `{0} Filiados(s) selecionado(s)`;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered>
          <Tab label={tabLabels[0]} />
          <Tab label={tabLabels[1]} />
        </Tabs>

        <Box sx={{ padding: 2 }}>
          {tabIndex === 0 && (
            <div>
              {isDetalhamento ? (
                <div>
                  {receitas ? (
                    <MaterialTable
                      columns={[
                        { title: 'Item', field: 'item_receita' },
                        { title: 'Tipo', field: 'tipo' },
                        { title: 'Descrição', field: 'descricao' },
                        {
                          title: 'Valor',
                          field: 'valor',
                          render: (row) => <span>{formatarValor(row.valor)}</span>,
                        },
                      ]}
                      data={receitas}
                      title=""
                      detailPanel={(row) => renderDetailReceita(row, canEdit, authUser)}
                      onRowClick={(event, rowData, togglePanel) => togglePanel()}
                      options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50, 100],
                        exportButton: true,
                        exportAllData: true,
                        exportCsv: (columns, data) => {
                          columns = columns
                            .concat([
                              { title: 'Data', field: 'data' },
                              { title: 'Parcela', field: 'parcela' },
                            ])
                            .filter((c) => !_.isEmpty(c.title));

                          data.forEach((d) => {
                            d.data = formataData(d.data);
                          });

                          downloadXlsx(columns, data, 'receitas.xlsx');
                        },
                      }}
                      localization={{
                        ...localization,
                        body: {
                          ...localization.body,
                          emptyDataSourceMessage: loadingFinancial ? (
                            <CircularProgress />
                          ) : (
                            'Nenhum dado disponível'
                          ),
                        },
                      }}
                      components={{
                        Toolbar: (props) => (
                          <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '10px' }}>
                              Total:{' '}
                              {formatarValor(
                                props.renderData
                                  .map((e) => calcularEstorno(e))
                                  .reduce(somadorDouble, 0)
                              )}
                            </div>
                          </div>
                        ),
                      }}
                    />
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nenhuma receita encontrada.
                    </Typography>
                  )}
                </div>
              ) : (
                <div>
                  {observacoes ? (
                    <Typography variant="body1">{observacoes}</Typography>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nenhuma observação encontrada.
                    </Typography>
                  )}
                </div>
              )}
            </div>
          )}
          {tabIndex === 1 && (
            <div>
              {isDetalhamento ? (
                <div>
                  {despesas ? (
                    <MaterialTable
                      columns={[
                        {
                          title: 'Tipo',
                          field: 'tipo_despesa',
                          render: (row) => {
                            return (
                              <span
                                style={{
                                  color: getCorDespesa(
                                    row.data_vencimento,
                                    row.data_liquidacao_pagamento
                                  ),
                                }}>
                                {row.tipo_despesa}
                              </span>
                            );
                          },
                        },
                        { title: 'Item', field: 'item_despesa' },
                        { title: 'Beneficiário', field: 'nome_beneficiario' },
                        { title: 'Parcela', field: 'parcela' },
                        {
                          title: 'Valor',
                          field: 'valor',
                          render: (row) => <span>{formatarValor(row.valor)}</span>,
                        },
                      ]}
                      data={despesas}
                      title=""
                      detailPanel={(row) => renderDetailDespesas(row, canEdit, authUser)}
                      onRowClick={(event, rowData, togglePanel) => togglePanel()}
                      options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50, 100],
                      }}
                      localization={{
                        ...localization,
                        body: {
                          ...localization.body,
                          emptyDataSourceMessage: loadingFinancial ? (
                            <CircularProgress />
                          ) : (
                            'Nenhum dado disponível'
                          ),
                        },
                      }}
                    />
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nenhuma despesa encontrada.
                    </Typography>
                  )}
                </div>
              ) : (
                <div>
                  {loadingHistorico ? (
                    <Grid container justifyContent="center" style={{ width: '100%' }}>
                      <CircularProgress />
                    </Grid>
                  ) : historico && historico.length > 0 ? (
                    <Grid container style={{ width: '100%' }} spacing={2}>
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Data</TableCell>
                              <TableCell>Alteração</TableCell>
                              <TableCell>Responsável</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {historico.map((row) => (
                              <TableRow key={row.id || row.data}>
                                <TableCell>{formataData(row.data)}</TableCell>
                                <TableCell>{renderAlteracoes(row.diff, anexos)}</TableCell>
                                <TableCell>{row.nome_responsavel}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nenhum histórico de alterações encontrado.
                    </Typography>
                  )}
                </div>
              )}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const renderAlteracoes = (diff, anexos) => {
  if (!diff || Object.keys(diff).length === 0) {
    return <Typography variant="body2">Nenhuma alteração registrada.</Typography>;
  }

  return Object.keys(diff).map((key) => {
    const value = diff[key];
    return renderAlteracaoItem(key, value, anexos);
  });
};

const renderAlteracaoItem = (key, value, anexos) => {
  if (key === 'tableData' || key === 'id') {
    return null;
  }

  return (
    <Typography variant="body2" key={key}>
      {`${getLabel(key)}: ${formatDiffValue(key, value, anexos)}`}
    </Typography>
  );
};

const formatDiffValue = (key, value, anexos) => {
  if (
    key === 'data_fim' ||
    key === 'data_inicio' ||
    key === 'data_carregamento' ||
    key === 'data_alerta'
  ) {
    return formataData(value);
  }

  if (key === 'anexos') {
    if (anexos) {
      const anexosNames = Object.keys(value)
        .map((anexoKey) => {
          const matchingAnexo = Object.values(anexos).find((anexo) => anexo.id === anexoKey);

          if (matchingAnexo) {
            if (matchingAnexo.situacao === 'Excluído') {
              return matchingAnexo.nome + ' (-)';
            } else if (matchingAnexo.situacao === 'Ativo') {
              return matchingAnexo.nome + ' (+)';
            }
          }
          return null;
        })
        .filter(Boolean);

      return anexosNames.length ? anexosNames.join(', ') : 'Nenhum anexo encontrado';
    }
    return 'Nenhum anexo encontrado';
  }

  if (key === 'emails') {
    if (
      (value && value.removed && Array.isArray(value.removed)) ||
      (value && value.added && Array.isArray(value.added))
    ) {
      if (Array.isArray(value.removed)) {
        return value.removed.map((item) => item.email).join(', ') + ' (-)';
      } else if (Array.isArray(value.added)) {
        return value.added.map((item) => item.email) + ' (+)';
      }
    } else {
      return [];
    }
  }

  if (key === 'cnpj_contratados') {
    if (
      (value && value.removed && Array.isArray(value.removed)) ||
      (value && value.added && Array.isArray(value.added))
    ) {
      if (Array.isArray(value.removed)) {
        return value.removed.map((item) => item.cnpj_contratado).join(', ') + ' (-)';
      } else if (Array.isArray(value.added)) {
        return value.added.map((item) => item.cnpj_contratado) + ' (+)';
      }
    } else {
      return [];
    }
  }

  return value;
};

const renderDetailDespesas = (despesa, canEdit, authUser) => {
  const {
    descricao,
    numero_parcelas,
    meio_pagamento,
    tipo_despesa,
    centro_custo,
    data_vencimento,
    id,
    anexos,
    data_liquidacao_pagamento,
  } = despesa;

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid container spacing={4}>
        <Grid item sm={8}>
          <Typography variant="body2" color="textSecondary">
            Descrição
          </Typography>
          <Typography variant="body1">{descricao}</Typography>
        </Grid>

        <Grid item sm={4}>
          <Typography variant="body2" align="right" color="textPrimary">
            Nº de parcelas: {numero_parcelas} | Meio de pagamento: {meio_pagamento} | {tipo_despesa}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">
            Centro de custo
          </Typography>
          <Typography variant="body1">{centro_custo}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">
            Data de vencimento
          </Typography>
          <Typography variant="body1">{formataData(data_vencimento)}</Typography>
        </Grid>
        <FileUpload
          id={id}
          anexos={anexos}
          pagamentoLiquidado={!!data_liquidacao_pagamento}
          handleDelete={(anexo) => this.deleteAnexo(despesa, anexo)}
          canEdit={canEdit}
          disabled={true}
        />
      </Grid>
    </Grid>
  );
};

const renderDetailReceita = (receita, canEdit) => {
  const { id, descricao, data, parcela, data_credito, anexos } = receita;

  return (
    <Grid container spacing={4} style={{ padding: 20 }}>
      <Grid item sm={8}>
        <Typography variant="body2" color="textSecondary">
          Descrição
        </Typography>
        <Typography variant="body1">{descricao}</Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant="body2" color="textSecondary">
          Parcela
        </Typography>
        <Typography variant="body1">{parcela}</Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant="body2" color="textSecondary">
          Data
        </Typography>
        <Typography variant="body1">{formataData(data)}</Typography>
      </Grid>
      {canEdit && (
        <Grid item container sm={12} spacing={2} justifyContent="space-between" alignItems="center">
          <FileUploadReceita
            id={id}
            anexos={anexos}
            receitaCreditada={!!data_credito}
            canEdit={canEdit}
            disabled={true}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Detalhamento;
