import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { formataData } from '../../commons/util';

const getLabel = (key) => {
  const labels = {
    n_patrimonio: 'Nº do patrimônio',
    tipo_objeto: 'Tipo de objeto',
    especificacao: 'Especificação',
    usuario: 'Nome do usuário',
    setor_alocado: 'Setor alocado',
    data_cadastro: 'Data de cadastro',
    n_serie: 'Nº de série',
    data_descarte: 'Data de descarte',
    status: 'Status',
    bem_relacionado: 'Nº do patrmônio',
    responsavel: 'Responsável',
    descricao_problema: 'Descrição do problema',
    data_manutencao: 'Data de manutenção',
    data_retorno: 'Data de retorno',
    motivo_movimentacao: 'Motivo da movimentaçao',
    data_saida: 'Data de saída',
    estado_patrimonio: 'Estado do patrimônio',
  };
  return labels[key] || key;
};

const HistoricoDeEdicao = ({
  open,
  onClose,
  historico,
  title,
  authUser,
  loadingHistorico,
  anexos,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered>
          <Tab label="Histórico de Edição" />
        </Tabs>

        <Box sx={{ padding: 2 }}>
          {tabIndex === 0 && (
            <div>
              {loadingHistorico ? (
                <Grid container justifyContent="center" style={{ width: '100%' }}>
                  <CircularProgress />
                </Grid>
              ) : historico && historico.length > 0 ? (
                <Grid container style={{ width: '100%' }} spacing={2}>
                  <Grid item xs={12}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Alteração</TableCell>
                          <TableCell>Responsável</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historico.map((row) => (
                          <TableRow key={row.id || row.data}>
                            <TableCell>{formataData(row.data)}</TableCell>
                            <TableCell>{renderAlteracoes(row.diff, anexos)}</TableCell>
                            <TableCell>{row.nome_responsavel}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Nenhum histórico de alterações encontrado.
                </Typography>
              )}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const renderAlteracoes = (diff, anexos) => {
  if (!diff || Object.keys(diff).length === 0) {
    return <Typography variant="body2">Nenhuma alteração registrada.</Typography>;
  }

  return Object.keys(diff).map((key) => {
    const value = diff[key];
    return renderAlteracaoItem(key, value, anexos);
  });
};

const renderAlteracaoItem = (key, value, anexos) => {
  if (key === 'tableData' || key === 'id') {
    return null;
  }

  return (
    <Typography variant="body2" key={key}>
      {`${getLabel(key)}: ${formatDiffValue(key, value, anexos)}`}
    </Typography>
  );
};

const formatDiffValue = (key, value, anexos) => {
  if (
    key === 'data_cadastro' ||
    key === 'data_descarte' ||
    key === 'data_manutencao' ||
    key === 'data_retorno' ||
    key === 'data_saida'
  ) {
    return formataData(value);
  }

  if (key === 'anexos') {
    if (anexos) {
      const anexosNames = Object.keys(value)
        .map((anexoKey) => {
          const matchingAnexo = Object.values(anexos).find((anexo) => anexo.id === anexoKey);

          if (matchingAnexo) {
            if (matchingAnexo.situacao === 'Excluído') {
              return matchingAnexo.nome + ' (-)';
            } else if (matchingAnexo.situacao === 'Ativo') {
              return matchingAnexo.nome + ' (+)';
            }
          }
          return null;
        })
        .filter(Boolean);

      return anexosNames.length ? anexosNames.join(', ') : 'Nenhum anexo encontrado';
    }
    return 'Nenhum anexo encontrado';
  }

  return value;
};

export default HistoricoDeEdicao;
