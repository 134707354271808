export default (values) => {
    const errors = {}
    const requiredFields = [
        'tipo_objeto', 'bem_relacionado', 'responsavel', 'data_retorno', 'hora_saida', 'hora_retorno', 'data_manutencao', 'descricao_problema'

    ]
    requiredFields.forEach((field) => {
        if (!values[field] || values[field].length === 0) {
            errors[field] = 'Obrigatório'
        }
    })
    return errors

}