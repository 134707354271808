import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Button,
  LinearProgress,
  Tooltip,
  Typography,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import { uploadAnexoContrato } from '../../../firebase/storage';
import { withSnackbar } from 'notistack';
import FolderIcon from '@material-ui/icons/Folder';
import ArquivosDocumentoContrato from './anexos';
import {
  getContrato,
  incluirAnexoContrato,
  removerAnexoContrato,
  setContratoId,
} from '../../../actions/gestao_contratos';
import ConfirmDialog from '../../commons/ConfirmDialog';
import DialogButton from '@material-ui/core/Button';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: 200,
  },
  fileInfo: {
    marginTop: theme.spacing(1),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
});

const AnexoContratosDocumentos = ({
  classes,
  contratoId,
  anexo: anexoProps = [],
  incluirAnexoContrato,
  removerAnexoContrato,
  enqueueSnackbar,
  onChange,
  disabled,
}) => {
  const [anexo, setAnexo] = useState(anexoProps);
  const [uploadProgressList, setUploadProgressList] = useState([]);
  const [arquivosDocumentoContratoOpen, setArquivosDocumentoContratoOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmButton, setConfirmButton] = useState('Confirmar');

  const handleCapture = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const selectedFiles = Array.from(files);
    setSelectedFile(selectedFiles);

    const initialProgress = selectedFiles.map((file) => ({
      file,
      progress: 0,
    }));

    setUploadProgressList(initialProgress);
    setConfirmDialogOpen(true);
  };

  const handleConfirmUpload = async () => {
    if (!contratoId) {
      setUploadProgressList([]);
      setConfirmDialogOpen(false);
      setConfirmButton('Confirmar');

      enqueueSnackbar('Arquivos carregados com sucesso!', { variant: 'success' });
      onChange([{ selectedFile }]);
      return;
    }

    setConfirmButton('Carregando...');

    let completedCount = 0;

    const handleStateChanged = (file) => (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgressList((prevState) =>
        prevState.map((item) => (item.file.name === file.name ? { ...item, progress } : item))
      );
    };

    const handleError = (error) => {
      console.error('Erro ao fazer upload do anexo:', error);
    };

    const handleSuccess = async (file, uploadTask) => {
      try {
        const metadata = await uploadTask.snapshot.ref.getMetadata();
        const anexo = {
          nome: file.name,
          url: metadata.fullPath,
          createdAt: metadata.timeCreated,
          situacao: 'Ativo',
        };
        setAnexo((prevAnexos) => {
          const anexosAtualizados = Array.isArray(prevAnexos) ? [...prevAnexos, anexo] : [anexo];
          return anexosAtualizados;
        });

        onChange([anexo, ...(Array.isArray(anexoProps) ? anexoProps : [])]);
        await incluirAnexoContrato(anexo, contratoId);
        await getContrato(contratoId);

        completedCount++;

        if (completedCount === selectedFile.length) {
          setUploadProgressList([]);
          setConfirmDialogOpen(false);
          setConfirmButton('Confirmar');
          enqueueSnackbar('Todos os arquivos foram carregados com sucesso!', {
            variant: 'success',
          });
        }
      } catch (error) {
        console.error('Erro ao incluir o anexo:', error);
        enqueueSnackbar('Erro ao incluir o anexo', { variant: 'error' });
      }
    };

    for (let i = 0; i < selectedFile.length; i++) {
      const file = selectedFile[i];
      const uploadTask = uploadAnexoContrato(`${contratoId}/${file.name}`, file);

      uploadTask.on('state_changed', handleStateChanged(file), handleError, () =>
        handleSuccess(file, uploadTask)
      );
    }
  };

  const handleCancelUpload = () => {
    setConfirmDialogOpen(false);
    setSelectedFile(null);
  };

  const handleArquivoDocumentoContratoOpen = () => {
    setArquivosDocumentoContratoOpen(true);
  };

  const handleArquivosDocumentoContrato = () => {
    setArquivosDocumentoContratoOpen(false);
  };

  const handleDeleteArquivoContrato = (contratoId, anexoId) => {
    return new Promise((resolve, reject) => {
      handleConfirmOpen(() => {
        removerAnexoContrato(contratoId, anexoId)
          .then(() => {
            enqueueSnackbar('Arquivo excluído com sucesso', { variant: 'success' });
            resolve();
          })
          .catch((error) => {
            enqueueSnackbar('Erro ao excluir arquivo', { variant: 'error' });
            reject(error);
          });
      }, 'Confirma a exclusão do arquivo?');
    });
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      confirmAction();
      setConfirmOpen(false);
    } else {
      setConfirmOpen(false);
    }
  };

  const handleConfirmOpen = (action, message) => {
    setConfirmAction(() => action);
    setConfirmMessage(message);
    setConfirmOpen(true);
  };

  const showArquivos =
    Object.values(anexo).filter((item) => item.situacao !== 'Excluído').length > 0 ||
    Object.values(anexoProps).filter((item) => item.situacao !== 'Excluído').length > 0;

  return (
    <>
      <Grid>
        <input
          className={classes.input}
          id="upload-anexo-contrato"
          onChange={handleCapture}
          type="file"
          multiple
        />
        <label htmlFor="upload-anexo-contrato">
          <Button
            color="primary"
            component="span"
            size="small"
            style={{ marginRight: 8 }}
            disabled={disabled}>
            <AttachFile />
            Arquivos
          </Button>
        </label>

        {showArquivos && (
          <Tooltip title="Arquivos">
            <Button
              color="primary"
              component="span"
              size="small"
              onClick={handleArquivoDocumentoContratoOpen}>
              <FolderIcon /> Ver arquivos
            </Button>
          </Tooltip>
        )}
      </Grid>

      <ArquivosDocumentoContrato
        open={arquivosDocumentoContratoOpen}
        handleClose={handleArquivosDocumentoContrato}
        handleDeleteArquivoContrato={handleDeleteArquivoContrato}
        disabled={disabled}
      />
      <ConfirmDialog open={confirmOpen} message={confirmMessage} onClose={handleConfirmClose} />
      <Dialog open={confirmDialogOpen} onClose={handleCancelUpload}>
        <DialogTitle>Confirmar Upload</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Você deseja fazer o upload dos arquivos selecionados?
          </Typography>

          {uploadProgressList.length > 0 &&
            uploadProgressList.map((item, index) => (
              <div key={index} style={{ marginTop: 8 }}>
                <Typography variant="body2">{item.file.name}</Typography>
                <LinearProgress
                  variant="determinate"
                  value={item.progress}
                  className={classes.progress}
                />
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleCancelUpload} color="primary">
            Cancelar
          </DialogButton>
          <DialogButton onClick={handleConfirmUpload} color="primary">
            {confirmButton}
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ contratos }) => ({ contratos });

export default connect(mapStateToProps, {
  getContrato,
  incluirAnexoContrato,
  removerAnexoContrato,
  setContratoId,
})(withStyles(styles, { withTheme: true })(withSnackbar(AnexoContratosDocumentos)));
