import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Container,
  withStyles,
  Card,
  Box,
  Button,
  Grid,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import * as routes from '../../constants/routes';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import DocumentoContratoForm from './documentoContrato';
import styles from './styles';
import { getContratos } from '../../actions/gestao_contratos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const GestaoDeContratos = ({ classes, authUser, enqueueSnackbar }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);
  const { contratos, loading } = useSelector((state) => state.contratos);
  const contratosValidos = contratos.filter((contrato) => contrato.situacao !== 'Excluído');
  const contratosEncerrados = contratos.filter((contrato) => contrato.situacao === 'Encerrado');

  useEffect(() => {
    dispatch(getContratos());
  }, [dispatch]);

  const handleOpenCreateDocument = () => {
    setOpen(true);
  };

  const handleCloseDocumentoContrato = () => {
    setOpen(false);
    dispatch(getContratos());
  };

  const renderSumarioVigentes = (key) => {
    const contratosVigentes = contratosValidos.filter(
      (contrato) => contrato.situacao === 'Vigente'
    );

    const handleClickContratoAtivos = () => {
      if (contratosVigentes.length > 0) {
        history.push(`${routes.GESTOR_CONTRATOS_DOCUMENTOS}?situacao=Vigente`);
      }
    };

    const handleClickContratosEncerrados = () => {
      if (contratosEncerrados.length > 0)
        history.push(`${routes.GESTOR_CONTRATOS_DOCUMENTOS}?situacao=Encerrado`);
    };

    const handleClickContratos = () => {
      if (contratosValidos.length > 0) {
        history.push(`${routes.GESTOR_CONTRATOS_DOCUMENTOS}`);
      }
    };

    return (
      <>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={handleClickContratoAtivos}>
          <Grid item xs={8} md={8}>
            <Typography variant="body1">Vigentes/Válidos</Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {contratosVigentes.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={handleClickContratosEncerrados}>
          <Grid item xs={8} md={8}>
            <Typography variant="body1">Encerrados/Arquivados</Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {contratosEncerrados.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={handleClickContratos}>
          <Grid item xs={8} md={8}>
            <Typography variant="body1">Todos os contratos/documentos</Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {contratosValidos.length}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderCardContentGrid = () => {
    return (
      <Card variant="outlined">
        <CardHeader title="Situações dos contratos ou documentos" />
        <CardContent>
          <Box mb={8}>
            <Grid container direction="column" alignItems="center">
              {renderSumarioVigentes()}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <SindilegisBar />
      <Loading loading={loading} />
      {!loading && (
        <Container maxWidth="xl" style={{ marginTop: 20 }}>
          <MenuButton buttonsRoutes={buttonRoutes} location={location} />
          <Box
            width={1}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Grid container spacing={3} direction="column">
              <Grid item xs={12} style={{ width: '100%' }}>
                <Typography variant="h6" align="left">
                  Contratos e outros documentos
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => handleOpenCreateDocument()}
                  style={{ marginTop: 20 }}>
                  Novo contrato ou documento
                </Button>
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <Box width="100%" display="flex" justifyContent="center">
                  {renderCardContentGrid()}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <DocumentoContratoForm
        open={open}
        handleClose={handleCloseDocumentoContrato}
        by={authUser?.email}
      />
    </div>
  );
};

const mapStateToProps = () => ({});

GestaoDeContratos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(mapStateToProps, {})
)(GestaoDeContratos);
