import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { storage } from '../../../firebase';
import tableI18n from '../../TableLocalization';
import styles from './styles';
import { connect, useDispatch } from 'react-redux';
import { getBem } from '../../../actions/patrimonio';

const ArquivosBemPatrimonial = ({
  handleClose,
  classes,
  open,
  bemId,
  handleDeleteArquivoBem,
  bem,
  disabled,
}) => {
  const [anexos, setAnexos] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && bemId) {
      dispatch(getBem(bemId));
    }
  }, [open, bemId, dispatch]);

  const loadAnexos = useCallback(() => {
    if (bem && bem.anexos) {
      setAnexos(Object.values(bem.anexos));
    }
  }, [bem]);

  useEffect(() => {
    loadAnexos();
  }, [bem, loadAnexos, bemId]);

  const handleDelete = (item) => {
    const { id, url } = item;
    handleDeleteArquivoBem(id, bemId, url)
      .then(() => {
        setAnexos((prevAnexos) => prevAnexos.filter((anexo) => anexo.id !== id));
      })
      .catch((error) => {
        console.error('Erro ao excluir arquivo:', error);
      });
  };

  const titulo = bem?.n_patrimonio;
  const anexosValidos = anexos.filter((anexo) => anexo.situacao === 'Ativo');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        <div className={classes.demo}>
          <MaterialTable
            columns={[
              {
                title: '',
                field: 'nome',
                render: (rowData) => (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => storage.downloadAnexo(rowData.url)}>
                    <Grid container alignItems="center">
                      {rowData.nome}
                    </Grid>
                  </Link>
                ),
              },
            ]}
            data={anexosValidos}
            title={'Arquivos'}
            options={{
              actionsColumnIndex: -1,
              sorting: false,
            }}
            actions={
              !disabled
                ? [
                    {
                      icon: 'delete',
                      tooltip: 'Excluir',
                      onClick: (event, row) => handleDelete(row),
                    },
                  ]
                : []
            }
            localization={tableI18n}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ patrimonio }) => ({
  bem: patrimonio.bem,
  bemId: patrimonio.bemId,
});

export default withStyles(styles)(connect(mapStateToProps, { getBem })(ArquivosBemPatrimonial));
