import {
  BENS_SET,
  BEM_SET,
  BEM_ID_SET,
  BENS_REQUEST,
  BEM_UPDATED,
  DELETE_BEM,
  HISTORICO_BEM_SET,
  FLUXOS_REQUEST,
  FLUXOS_SET,
  FLUXO_ID_SET,
  FLUXO_SET,
  FLUXO_UPDATED,
  DELETE_FLUXO,
  HISTORICO_FLUXO_SET,
  HISTS_MANUTENCAO_SET,
  HIST_MANUTENCAO_SET,
  HIST_MANUTENCAO_ID_SET,
  HISTS_MANUTENCAO_REQUEST,
  HIST_MANUTENCAO_UPDATED,
  DELETE_HIST_MANUTENCAO,
  HISTORICO_MANUTENCAO_SET
} from '../actions/types';

const INITIAL_STATE = {
  bens: [],
  fluxos: [],
  histsManutencao: [],
  bem: null,
  fluxo: null,
  histManutencao: null,
  bemId: null,
  fluxoId: null,
  histManutencaoId: null,
  historicoBem: [],
  historicoFluxo: [],
  historicoManutencao: [],
  loadingBem: false,
  loadingFluxo: false,
  loadingHistManutencao: false
};

function bemPatrimonialReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BENS_SET: {
      return { ...state, bens: action.bens, loadingBem: false };
    }
    case BENS_REQUEST: {
      return { ...state, loadingBem: true };
    }
    case BEM_SET: {
      return { ...state, bem: action.bem, loadingBem: false };
    }
    case BEM_ID_SET: {
      return { ...state, bemId: action.bemId };
    }
    case HISTORICO_BEM_SET: {
      return { ...state, historicoBem: action.historicoBem }
    }
    case BEM_UPDATED: {
      const bens = Object.values(state.bens);
      const { id, data } = action.payload;
      const index = bens.findIndex((f) => f.id === id);
      const bem = { ...bens[index], ...data };
      bens.splice(index, 1, bem);
      return { ...state, bens: [...bens] };
    }
    case DELETE_BEM: {
      const { bens } = state;
      const index = bens.findIndex((f) => f.id === action.payload.id);
      bens.splice(index, 1);
      return { ...state, bens: [...bens] };
    }
    case FLUXOS_SET: {
      return { ...state, fluxos: action.fluxos, loadingFluxo: false };
    }
    case FLUXOS_REQUEST: {
      return { ...state, loadingFluxo: true };
    }
    case FLUXO_SET: {
      return { ...state, fluxo: action.fluxo, loadingFluxo: false };
    }
    case FLUXO_ID_SET: {
      return { ...state, fluxoId: action.fluxoId };
    }
    case HISTORICO_FLUXO_SET: {
      return { ...state, historicoFluxo: action.historicoFluxo }
    }
    case FLUXO_UPDATED: {
      const fluxos = Object.values(state.fluxos);
      const { id, data } = action.payload;
      const index = fluxos.findIndex((f) => f.id === id);
      const fluxo = { ...fluxos[index], ...data };
      fluxos.splice(index, 1, fluxo);
      return { ...state, fluxos: [...fluxos] };
    }
    case DELETE_FLUXO: {
      const { fluxos } = state;
      const index = fluxos.findIndex((f) => f.id === action.payload.id);
      fluxos.splice(index, 1);
      return { ...state, fluxos: [...fluxos] };
    }
    case HISTS_MANUTENCAO_SET: {
      return { ...state, histsManutencao: action.histsManutencao, loadingHistManutencao: false };
    }
    case HISTS_MANUTENCAO_REQUEST: {
      return { ...state, loadingHistManutencao: true };
    }
    case HIST_MANUTENCAO_SET: {
      return { ...state, histManutencao: action.histManutencao, loadingHistManutencao: false };
    }
    case HIST_MANUTENCAO_ID_SET: {
      return { ...state, histManutencaoId: action.histManutencaoId };
    }
    case HISTORICO_MANUTENCAO_SET: {
      return { ...state, historicoManutencao: action.historicoManutencao }
    }
    case HIST_MANUTENCAO_UPDATED: {
      const histsManutencao = Object.values(state.histsManutencao);
      const { id, data } = action.payload;
      const index = histsManutencao.findIndex((f) => f.id === id);
      const histManutencao = { ...histsManutencao[index], ...data };
      histsManutencao.splice(index, 1, histManutencao);
      return { ...state, histsManutencao: [...histsManutencao] };
    }
    case DELETE_HIST_MANUTENCAO: {
      const { histsManutencao } = state;
      const index = histsManutencao.findIndex((f) => f.id === action.payload.id);
      histsManutencao.splice(index, 1);
      return { ...state, histsManutencao: [...histsManutencao] };
    }
    default:
      return state;
  }
}

export default bemPatrimonialReducer;
