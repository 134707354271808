export const GESTOR_USUARIO = 'gestor_usuario';
export const VISUALIZADOR_USUARIO = 'visualizador_usuario';
export const EDITOR_FILIADO = 'editor_filiado';
export const ADMIN_FINANCEIRO = 'admin_financeiro';
export const VISUALIZADOR_FINANCEIRO = 'visualizador_financeiro';
export const GESTOR_SISTEMA = 'gestor_sistema';
export const APROVADOR = 'aprovador';
export const GESTOR_EVENTO = 'gestor_evento';
export const VISUALIZADOR_EVENTO = 'visualizador_evento';
export const GESTOR_INTERACAO = 'gestor_interacao';
export const GESTOR_FUNCIONARIOS = 'gestor_funcionarios';
export const JURIDICO = 'juridico';
export const VISUALIZADOR_JURIDICO = 'visualizador_juridico';
export const COMUNICACAO = 'comunicacao';
export const ESCRITORIO_JURIDICO = 'escritorio_juridico';
export const FILIADO = 'filiado';
export const POLITICO_SINDICAL = 'politico_sindical';
export const VISUALIZADOR_CONTRATOS = 'visualizador_contratos';
export const PESQUISA_SATISFACAO = 'pesquisa_satisfacao';
export const AUTORIDADES = 'autoridades';
export const CONSIGNACAO = 'consignacao';
export const VISUALIZADOR_CONSIGNACAO = 'visualizador_consignacao';
export const SINDEX = 'sindex';
export const Verificador = 'verificador_filiado';
export const POWER_BI = 'power_bi'
export const GESTOR_CONTRATOS = 'gestor_de_contratos'
export const PATRIMONIO = 'patrimonio';

export const rolesDescription = {
  gestor_usuario: 'Gestor de Filiados',
  visualizador_usuario: 'Visualizador de Filiados',
  editor_filiado: 'Editor de Filiados',
  admin_financeiro: 'Financeiro',
  visualizador_financeiro: 'Visualizador do Financeiro',
  visualizador_contratos: 'Visualizador do Contratos',
  gestor_sistema: 'Gestor do Sistema',
  aprovador: 'Aprovador',
  gestor_evento: 'Gestor de Eventos',
  visualizador_evento: 'Visualizador de Eventos',
  gestor_interacao: 'Gestor de Interação',
  gestor_funcionarios: 'Gestor de Funcionários',
  juridico: 'Jurídico',
  visualizador_juridico: 'Visualizador do Jurídico',
  comunicacao: 'Comunicação',
  escritorio_juridico: 'Escritório Jurídico',
  filiado: 'Filiado',
  politico_sindical: 'Político Sindical',
  pesquisa_satisfacao: 'Pesquisa de Satisfação',
  autoridades: 'Autoridades',
  consignacao: 'Consignação',
  visualizador_consignacao: 'Visualizador Consignações',
  sindex: 'Sindex',
  verificador_filiado: 'Verificador',
  power_bi: 'Power BI',
  gestor_de_contratos: 'Gestor de Contratos',
  patrimonio: 'Patrimônio',
};
