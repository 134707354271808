import React, { useState, useEffect, useCallback } from 'react';
import { reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Container, Fab, Grid, Typography, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { formataData, formataHora } from '../../Financial/util';
import * as routes from '../../../constants/routes';
import tableI18n from '../../TableLocalization';
import SindilegisBar from '../../commons/SindilegisBar';
import styles from './../styles';
import Loading from '../../commons/Loading';
import { getRoutes, downloadXlsx } from '../../commons/util';
import MenuButton from '../../commons/MenuButton';
import { withSnackbar } from 'notistack';
import { getFluxos, getHistoricoFluxo, setFluxo, setFluxoId } from '../../../actions/patrimonio';
import FluxForm from './fluxo';
import _ from 'lodash';
import HistoricoDeEdicao from '../common/historicoDeEdicao';

const Fluxo = ({ classes, authUser, location }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [openFluxo, setOpenFluxo] = useState(false);
  const [openHistoricos, setOpenHistoricos] = useState(false);
  const [historicosFluxos, setHistoricosFluxos] = useState([]);
  const [title, setTitle] = useState('');
  const [loadingHistorico, setLoadingHitorico] = useState(false);
  const { fluxos, loadingFluxo } = useSelector((state) => state.patrimonio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFluxos());
  }, [dispatch]);

  const handleEditFluxo = (id) => {
    dispatch(setFluxoId(id));
    setOpenFluxo(true);
  };

  const handleCloseFluxo = useCallback(() => {
    dispatch(setFluxo(null));
    dispatch(setFluxoId(null));
    setOpenFluxo(false);
    setIsCreating(false);
    dispatch(getFluxos());
    reset();
  }, [dispatch]);

  const handleOpenHistorico = async (row) => {
    setLoadingHitorico(true);
    const historicos = await dispatch(getHistoricoFluxo(row?.id));
    setLoadingHitorico(false);
    setHistoricosFluxos(historicos);
    setOpenHistoricos(true);
    setTitle(row?.bem_relacionado);
  };

  const handleCloseHistoricos = () => {
    setOpenHistoricos(false);
    setTitle('');
  };

  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  return (
    <React.Fragment>
      <SindilegisBar />
      <Loading loading={loadingFluxo} />
      {!loadingFluxo && (
        <Container maxWidth="xl">
          <MenuButton buttonsRoutes={buttonRoutes} location={location} />
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: 'Nº Patrimônio',
                    field: 'bem_relacionado',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Nome do Responsável',
                    field: 'responsavel',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Motivo da Movimentação',
                    field: 'motivo_movimentacao',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Data de Saída',
                    field: 'data_saida',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) =>
                      formataData(rowData.data_saida) + ' ' + formataHora(rowData.hora_saida),
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Data de Retorno',
                    field: 'data_retorno',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) =>
                      formataData(rowData.data_retorno) + ' ' + formataHora(rowData.hora_retorno),
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Estado do Patrimônio',
                    field: 'estado_patrimonio',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                ]}
                data={Object.values(fluxos)}
                title="Controle de Fluxo"
                actions={[
                  (rowData) => {
                    const { id } = rowData;
                    return {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: () => {
                        handleEditFluxo(id);
                      },
                    };
                  },
                  {
                    icon: 'notes',
                    tooltip: 'Histórico de edições',
                    onClick: (event, rowData) => {
                      handleOpenHistorico(rowData);
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  search: true,
                  exportCsv: (columns, data) => {
                    data.forEach((d) => {
                      d.data_saida = formataData(d.data_saida) + ' - ' + formataHora(d.hora_saida);
                      d.data_retorno =
                        formataData(d.data_retorno) + ' - ' + formataHora(d.hora_retorno);
                    });

                    downloadXlsx(
                      _.filter(columns, (item) => !item.noExport),
                      data,
                      'controle_de_fluxo.xlsx'
                    );
                  },
                }}
                localization={tableI18n}
              />
              <Fab
                color="primary"
                aria-label="Cadastrar"
                className={classes.fab}
                onClick={() => setIsCreating(true) & setOpenFluxo(true)}>
                <AddIcon />
              </Fab>
            </div>
          </Grid>
        </Container>
      )}
      <FluxForm
        open={openFluxo}
        handleClose={handleCloseFluxo}
        by={authUser?.email}
        isCreating={isCreating}
      />
      <HistoricoDeEdicao
        open={openHistoricos}
        onClose={handleCloseHistoricos}
        title={title}
        historico={historicosFluxos}
        loadingHistorico={loadingHistorico}
      />
    </React.Fragment>
  );
};

Fluxo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(withRouter(Fluxo)));
