import {
  EXPENSES_SET,
  EXPENSE_ID_SET,
  EXPENSE_SET,
  EXPENSE_UPDATED,
  DELETE_EXPENSE,
  LIQUIDAR_PAGAMENTO,
  RECEITAS_SET,
  RECEITA_ID_SET,
  RECEITA_UPDATED,
  RECEITA_SET,
  DELETE_RECEITA,
  RECEITA_DESPESA_REQUEST,
  RECEITAS_E_DEPESAS_SET,
  HISTORICO_DESPESA_SET,
  EXPENSE_REQUEST,
  RECEITA_REQUEST,
} from '../actions/types';

const INITIAL_STATE = {
  expenses: [],
  expenseId: null,
  expense: null,
  receitas: [],
  receitaId: null,
  receita: null,
  loading: false,
  loadingExpense: false,
  loadingReceita: false,
  historico: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXPENSES_SET:
      return { ...state, expenses: action.expenses };
    case RECEITA_DESPESA_REQUEST: {
      return { ...state, loading: true };
    }
    case EXPENSE_ID_SET:
      return { ...state, expenseId: action.expenseId };
    case EXPENSE_REQUEST:
      return { ...state, loadingExpense: true }
    case EXPENSE_SET:
      return { ...state, expense: action.expense, loadingExpense: false };
    case DELETE_EXPENSE: {
      const { expenses } = state;
      const index = expenses.findIndex((d) => d.id === action.payload.id);
      expenses.splice(index, 1);
      return { ...state, expenses: [...expenses] };
    }
    case HISTORICO_DESPESA_SET: {
      return { ...state, historico: action.historico };
    }
    case RECEITAS_SET:
      return { ...state, receitas: action.receitas };
    case RECEITAS_E_DEPESAS_SET:
      return { ...state, receitas: action.receitas, expenses: action.expenses, loading: false };
    case RECEITA_ID_SET:
      return { ...state, receitaId: action.receitaId };
    case RECEITA_REQUEST:
      return { ...state, loadingReceita: true }
    case RECEITA_SET:
      return { ...state, receita: action.receita, loadingReceita: false };
    case DELETE_RECEITA: {
      const { receitas } = state;
      const index = receitas.findIndex((r) => r.id === action.payload.id);
      receitas.splice(index, 1);
      return { ...state, receitas: [...receitas] };
    }
    case LIQUIDAR_PAGAMENTO: {
      const { expenses } = state;
      const { id, data: despesa } = action.payload;
      const index = expenses.findIndex((f) => f.id === id);
      const expense = { ...expenses[index], ...despesa };
      expenses.splice(index, 1, expense);
      return { ...state, expenses: [...expenses] };
    }
    case EXPENSE_UPDATED: {
      const { expenses } = state;
      const { despesa } = action;
      const index = expenses.findIndex((f) => f.id === despesa.id);
      const expense = { ...expenses[index], ...despesa };
      expenses.splice(index, 1, expense);
      return { ...state, expenses: [...expenses] };
    }
    case RECEITA_UPDATED: {
      const { receitas } = state;
      const { receita } = action;
      const index = receitas.findIndex((f) => f.id === receita.id);
      const expense = { ...receitas[index], ...receita };
      receitas.splice(index, 1, expense);
      return { ...state, receitas: [...receitas] };
    }
    default:
      return state;
  }
}
