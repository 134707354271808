import React, { useState, useEffect, useCallback } from 'react';
import { reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Container, Fab, Grid, Typography, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { formataData } from '../../Financial/util';
import * as routes from '../../../constants/routes';
import tableI18n from '../../TableLocalization';
import SindilegisBar from '../../commons/SindilegisBar';
import styles from '../styles';
import Loading from '../../commons/Loading';
import { getRoutes, downloadXlsx } from '../../commons/util';
import MenuButton from '../../commons/MenuButton';
import { withSnackbar } from 'notistack';
import {
  getHistoricoManutencao,
  getHistsManutencao,
  setHistManutencao,
  setHistManutencaoId,
} from '../../../actions/patrimonio';
import HistManutencaoForm from './histManutencao';
import _ from 'lodash';
import HistoricoDeEdicao from '../common/historicoDeEdicao';

const HistManutencao = ({ classes, authUser, location }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [openHistManutencao, setOpenHistManutencao] = useState(false);
  const [openHistoricos, setOpenHistoricos] = useState(false);
  const [historicosManutencao, setHistoricosManutencao] = useState([]);
  const [title, setTitle] = useState('');
  const [loadingHistorico, setLoadingHitorico] = useState(false);
  const { histsManutencao, loadingHistManutencao } = useSelector((state) => state.patrimonio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHistsManutencao());
  }, [dispatch]);

  const handleEditHistManutencao = (id) => {
    dispatch(setHistManutencaoId(id));
    setOpenHistManutencao(true);
  };

  const handleCloseHistManutencao = useCallback(() => {
    dispatch(setHistManutencao(null));
    dispatch(setHistManutencaoId(null));
    setOpenHistManutencao(false);
    setIsCreating(false);
    dispatch(getHistsManutencao());
    reset();
  }, [dispatch]);

  const handleOpenHistorico = async (row) => {
    setLoadingHitorico(true);
    const historicos = await dispatch(getHistoricoManutencao(row?.id));
    setLoadingHitorico(false);
    setHistoricosManutencao(historicos);
    setOpenHistoricos(true);
    setTitle(row?.bem_relacionado);
  };

  const handleCloseHistoricos = () => {
    setOpenHistoricos(false);
    setTitle('');
  };

  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  return (
    <React.Fragment>
      <SindilegisBar />
      <Loading loading={loadingHistManutencao} />
      {!loadingHistManutencao && (
        <Container maxWidth="xl">
          <MenuButton buttonsRoutes={buttonRoutes} location={location} />
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: 'Nº Patrimônio',
                    field: 'bem_relacionado',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Responsável pela Manutenção',
                    field: 'responsavel',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Descrição do Problema',
                    field: 'descricao_problema',
                    headerStyle: { whiteSpace: 'nowrap' },
                    cellStyle: {
                      padding: '5px 10px',
                    },
                  },
                  {
                    title: 'Data de Manutenção',
                    field: 'data_manutencao',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) => formataData(rowData.data_manutencao),
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Data de Retorno',
                    field: 'data_retorno',
                    headerStyle: { whiteSpace: 'nowrap' },
                    render: (rowData) => formataData(rowData.data_retorno),
                    cellStyle: { whiteSpace: 'nowrap' },
                  },
                  {
                    title: 'Tipo de Objeto',
                    field: 'tipo_objeto',
                    headerStyle: { whiteSpace: 'nowrap' },
                  },
                ]}
                data={Object.values(histsManutencao)}
                title="Histórico de manutenção"
                actions={[
                  (rowData) => {
                    const { id } = rowData;
                    return {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: () => {
                        handleEditHistManutencao(id);
                      },
                    };
                  },
                  {
                    icon: 'notes',
                    tooltip: 'Histórico de edições',
                    onClick: (event, rowData) => {
                      handleOpenHistorico(rowData);
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  exportCsv: (columns, data) => {
                    data.forEach((d) => {
                      d.data_manutencao = formataData(d.data_manutencao);
                      d.data_retorno = formataData(d.data_retorno);
                    });

                    downloadXlsx(
                      _.filter(columns, (item) => !item.noExport),
                      data,
                      'historico_manutencao.xlsx'
                    );
                  },
                }}
                localization={tableI18n}
              />
              <Fab
                color="primary"
                aria-label="Cadastrar"
                className={classes.fab}
                onClick={() => setIsCreating(true) & setOpenHistManutencao(true)}>
                <AddIcon />
              </Fab>
            </div>
          </Grid>
        </Container>
      )}
      <HistManutencaoForm
        open={openHistManutencao}
        handleClose={handleCloseHistManutencao}
        by={authUser?.email}
        isCreating={isCreating}
      />
      <HistoricoDeEdicao
        open={openHistoricos}
        onClose={handleCloseHistoricos}
        title={title}
        historico={historicosManutencao}
        loadingHistorico={loadingHistorico}
      />
    </React.Fragment>
  );
};

HistManutencao.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(withRouter(HistManutencao)));
