import React from 'react';
import { Field } from 'redux-form';
import InputText from '../../commons/InputText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { validateEmail } from '../../commons/util';
import PlusIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';

export default (props) => {
  const { fields, disabled } = props;
  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          hidden={false}
          alignItems="flex-start"
          justifyContent="flex-start">
          <Grid item sm={8}>
            <Field
              name={`${item}.email`}
              component={InputText}
              multiline={true}
              minRows={2}
              label={`E-mail`}
              disabled={disabled}
              validate={validateEmail}
            />
          </Grid>
          <Grid item sm={1}>
            <IconButton
              onClick={() => fields.remove(index)}
              aria-label="Excluir"
              disabled={disabled}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <IconButton
        variant="insert"
        size="small"
        disabled={disabled}
        onClick={() => {
          fields.push();
        }}>
        <PlusIcon />{' '}
        <Typography variant="body2">Adicionar e-mails para alerta de rescisão/renovação</Typography>
      </IconButton>
    </React.Fragment>
  );
};
