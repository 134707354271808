import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValues } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { db } from '../../firebase';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import Switch from '../commons/Switch';
import { cpfMask, cepMask, dateMask, celularMask, telefoneMask } from '../commons/masks';
import { withSnackbar } from 'notistack';
import { changeUserEmail, createFiliado as createFiliadoFunction } from '../../firebase/functions';
import InputCep from '../commons/InputCep';
import styles from './styles';
import {
  getFiliado,
  saveFiliado,
  setFiliado,
  setFiliadoId,
  removerAnexoFiliado,
  incluirAnexoFiliado,
  criarLog
} from '../../actions/filiado';
import validate from './validate';
import * as config from './config';
import HistoricoFiliado from './historico_filiado';
import AnexoFiliado from './anexos/anexo_filiado';
import Person from '@material-ui/icons/Person';
import { Checkbox, FormControlLabel, Radio, Typography } from '@material-ui/core';
import Work from '@material-ui/icons/Work';
import { validateEmail } from '../commons/util';
import Phone from '@material-ui/icons/Phone';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import { preferenciaEmail } from './config';
import RadioButton from '../commons/RadioButton';
import _ from 'lodash';
import InputWithPrefix from './InputWithPrefix';
import DependentList from '../DependentList';
import Group from '@material-ui/icons/Group';
import Parentescos from '../DependentList/config';
import InputNumber from '../commons/InputNumber';
import { uploadAnexoFiliado } from '../../firebase/storage';

class UserDetail extends React.Component {
  state = {
    loading: false,
    anexos: this.props.filiado?.anexos || [],
    anexosTemp: [],
    showPersonalInfo: false,
    showContacts: false,
    showProfInfo: false,
    showFichaCadastral: false,
    showDepentents: false,
    selectedCasa: '',
    matricula: '',
    arquivosFiliadoOpen: false,
    filiadoId: null,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
  };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.saveFiliado = this.saveFiliado.bind(this);
    this.onSucessCep = this.onSucessCep.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { filiadoId, getFiliado } = this.props
    if (filiadoId && filiadoId !== prevProps.filiadoId) {
      getFiliado(filiadoId).then((filiado) => {
        if (filiado && filiado.empresa === 'CAMARA' && filiado.ponto) {
          this.setState({
            selectedCasa: 'CAMARA',
            matricula: filiado.ponto,
          });
        } else {
          this.setState({ selectedCasa: '', matricula: '' });
        }
      });
    }
  }

  handlePhoneKeyDown = (e) => {
    const { change } = this.props
    const { name, value } = e.target;
    if (e.keyCode === 8 && value.length === 11) {
      const newValue = value.slice(0, -1);
      change(name, newValue);
    }
  };

  async save(values) {
    const { enqueueSnackbar, filiadoId, filiado, handleClose, incluirAnexoFiliado, criarLog } = this.props;
    const { anexosTemp } = this.state;

    this.setState({ loading: true });

    values.email = values.email.toLowerCase();

    if (filiadoId && filiado.email !== values.email) {
      await changeUserEmail(filiadoId, values.email)
        .then(() => {
          this.saveFiliado(filiadoId, values);
        })
        .catch(({ message }) => {
          this.setState({ loading: false });
          enqueueSnackbar('Erro ao alterar o email do usuario.' + message, {
            variant: 'error',
          });
        });
    } else {
      if (filiadoId) {
        this.saveFiliado(filiadoId, values);
      } else {
        createFiliadoFunction(values)
          .then(async (userId) => {
            const id = userId.data.uid;
            if (id && Object.values(anexosTemp).length > 0) {
              for (const anexo of anexosTemp) {
                const uploadTask = uploadAnexoFiliado(`${id}/${anexo.selectedFile.name}`, anexo.selectedFile);
                await uploadTask;

                await uploadTask.snapshot.ref.getMetadata().then((metadata) => {
                  const anexoData = { nome: anexo.selectedFile.name, url: metadata.fullPath };
                  incluirAnexoFiliado(anexoData, id);
                });
              }
            }
            criarLog(id)
            enqueueSnackbar('Usuário criado com sucesso!', {
              variant: 'success',
            });
            this.setState({ loading: false });
            handleClose(true);
          })
          .catch((error) => {
            this.setState({ loading: false });
            enqueueSnackbar(error.message, { variant: 'error' });
          });
      }
    }
  }

  saveFiliado(filiadoId, values) {
    const { enqueueSnackbar, handleClose } = this.props;
    db.doSaveFiliado(filiadoId, values)
      .then(() => {
        enqueueSnackbar('Usuário atualizado com sucesso!', {
          variant: 'success',
        });
        this.setState({ loading: false });
        handleClose(filiadoId);
      })
      .catch(
        () =>
          this.setState({ loading: false }) &
          enqueueSnackbar('Erro ao atualizar os dados do usuário.', {
            variant: 'error',
          })
      );
  }

  onSucessCep(data) {
    const { enqueueSnackbar, change } = this.props;
    const { uf, logradouro, bairro, localidade, erro } = data;
    if (erro) {
      enqueueSnackbar('CEP não encontrado.', {
        variant: 'error',
      });
      return;
    }
    change('logradouro', logradouro);
    change('bairro', bairro);
    change('cidade', localidade);
    change('estado', uf);
  }

  togglePersonalInfo() {
    this.setState((prevState) => ({
      showPersonalInfo: !prevState.showPersonalInfo,
    }));
  }

  toggleContactsInfo() {
    this.setState((prevState) => ({
      showContacts: !prevState.showContacts,
    }));
  }

  toggleProfInfo() {
    this.setState((prevState) => ({
      showProfInfo: !prevState.showProfInfo,
    }));
  }

  toggleFichaCadastral() {
    this.setState((prevState) => ({
      showFichaCadastral: !prevState.showFichaCadastral,
    }));
  }

  openDependents() {
    this.setState({ showDepentents: true });
  }

  closeDependents = () => {
    this.setState({ showDepentents: false });
  };

  handleCasaChange = (event) => {
    const { change } = this.props;
    const selectedCasa = event.target.value;
    this.setState({
      selectedCasa,
      matricula: '',
    });

    change('ponto', '');
  };

  handleMatriculaChange = (event) => {
    const { change } = this.props;
    const { selectedCasa } = this.state;
    const matricula = event?.target?.value;
    this.setState({ matricula });

    if (selectedCasa === 'CAMARA') {
      change('ponto', matricula);
    }
  };

  handleFalecido = (event) => {
    const { change } = this.props
    const data = new Date().getDate()
    const situacaoInativo = 'INATIVO'
    const situacaoAtivo = 'ATIVO'
    const isFiliadoFalecido = event.target.checked

    if (isFiliadoFalecido) {
      change('data_desfiliacao', data)
      change('situacao_filiado', situacaoInativo)
    } else {
      change('data_desfiliacao', '')
      change('situacao_filiado', situacaoAtivo)
    }
  }



  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      reset,
      submitting,
      filiado,
      open,
      canEdit,
      isEditor,
      filiadoId,
      change,
      handleClose,
      initialValues
    } = this.props;

    const {
      loading,
      showContacts,
      showPersonalInfo,
      showProfInfo,
      showFichaCadastral,
      showDepentents,
      selectedCasa,
    } = this.state;

    const isFiliadoInativo = filiado?.situacao_filiado === 'INATIVO';
    const isFiliadoCamara = selectedCasa === 'CAMARA' || filiado?.empresa === 'CAMARA';

    return (
      <Dialog
        open={open}
        onClose={handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        {filiado && filiado.data_desfiliacao && (
          <h4 style={{ color: 'red', paddingLeft: '20px' }}>
            Atenção: servidor não é mais filiado.
          </h4>
        )}

        <form className={classes.container} onSubmit={handleSubmit(this.save)}>
          <DialogTitle id="form-dialog-title">Filiado</DialogTitle>
          {open && (
            <DialogContent style={{ minHeight: '50vh' }}>
              {loading ? (
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={50} />
                  <Typography variant="h6" style={{ marginTop: 16 }}>
                    Aguarde, estamos registrando o filiado no sistema.
                  </Typography>
                </Grid>) :
                <Grid container spacing={4} style={loading ? { display: 'none' } : {}}>
                  {/* Informações Pessoais */}
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => this.togglePersonalInfo()}
                        size="small">
                        <Person color="primary" />
                        <Typography style={{ marginLeft: 8 }} color="primary">
                          Informações Pessoais
                        </Typography>
                      </Button>
                    </Grid>
                    {showPersonalInfo && (
                      <>
                        <Grid item sm={8}>
                          <Field name="nome_completo" component={InputText} label="Nome" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="cpf" component={InputText} label="CPF" {...cpfMask} />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="falecido" component={Switch} label="Falecido" onChange={this.handleFalecido} />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="rg" component={InputText} label="RG" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field
                            name="orgao_expedidor"
                            component={InputText}
                            label="Órgão Expedidor"
                          />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="UF_orgao_expedidor" component={Select} label="UF Órgão">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.estados.map((estado) => (
                              <MenuItem key={estado} value={estado}>
                                {estado}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item sm={2}>
                          <Field
                            name="data_nascimento"
                            component={InputText}
                            {...dateMask}
                            label="Nascimento"
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field name="sexo" component={Select} label="Gênero">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.sexos.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <SexoOutro />
                        <Grid item sm={2}>
                          <Field name="estado_civil" component={Select} label="Estado Civil">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.estadoCivil.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item sm={8}>
                          <Field name="nome_mae" component={InputText} label="Nome da mãe" />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* Contatos */}
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => this.toggleContactsInfo()}
                        size="small">
                        <Phone color="primary" />
                        <Typography style={{ marginLeft: 8 }} color="primary">
                          Contatos
                        </Typography>
                      </Button>
                    </Grid>
                    {showContacts && (
                      <>
                        <Grid item sm={4}>
                          <Field name="email" component={InputText} label="E-mail Pessoal" />
                        </Grid>
                        <Grid item sm={4}>
                          <Field
                            name="email_funcional"
                            component={InputText}
                            label="E-mail Funcional"
                            validate={validateEmail}
                          />
                        </Grid>

                        <Grid item sm={4}>
                          <Field
                            name="preferencia_comunicacao"
                            component={RadioButton}
                            label={
                              <span style={{ fontSize: '0.8rem' }}>
                                Prefere receber nossas comunicações no e-mail
                              </span>
                            }>
                            {_.map(preferenciaEmail, (preferenciaEmail, key) => (
                              <FormControlLabel
                                key={key}
                                value={preferenciaEmail.value}
                                control={<Radio />}
                                label={
                                  <span style={{ fontSize: '0.8rem' }}>{preferenciaEmail.label}</span>
                                }
                              />
                            ))}
                          </Field>
                        </Grid>

                        {/* Campo de celular com WhatsApp */}
                        <Grid item sm={6} container spacing={2}>
                          <Grid item sm={4}>
                            <Field
                              name="tel_celular"
                              component={InputText}
                              label="Celular"
                              {...celularMask}
                              onKeyDown={this.handlePhoneKeyDown}
                            />
                          </Grid>
                          <Grid item sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <Field
                              name="whatsapp"
                              type="checkbox"
                              component={({ input }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox {...input} checked={input.value ? true : false} />
                                  }
                                  label="esse número é WhatsApp"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name="tel_fixo"
                            component={InputText}
                            label="Telefone Residencial"
                            {...telefoneMask}
                            onKeyDown={this.handlePhoneKeyDown}
                          />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="ramal" component={InputText} label="Ramal" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field
                            name="cep"
                            component={InputCep}
                            {...cepMask}
                            label="CEP"
                            onSuccess={this.onSucessCep}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Field name="logradouro" component={InputText} label="Logradouro" />
                        </Grid>
                        <Grid item sm={3}>
                          <Field name="bairro" component={InputText} label="Bairro" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="cidade" component={InputText} label="Cidade" />
                        </Grid>
                        <Grid item sm={1}>
                          <Field name="estado" component={Select} label="Estado">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.estados.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item sm={12}>
                          <Grid item>
                            <Typography variant="body1">Contato de emergência</Typography>
                          </Grid>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="nome_ct_emergencia" component={InputText} label="Nome" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field
                            name="celular_ct_emergencia"
                            component={InputText}
                            label="Celular"
                            {...celularMask}
                            onKeyDown={this.handlePhoneKeyDown}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name="parentesco_ct_emergencia"
                            component={Select}
                            label="Parentesco">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {Parentescos.map((p) => (
                              <MenuItem key={p} value={p}>
                                {p}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* Informações Profissionais */}
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => this.toggleProfInfo()}
                        size="small">
                        <Work color="primary" />
                        <Typography style={{ marginLeft: 8 }} color="primary">
                          Informações Profissionais
                        </Typography>
                      </Button>
                    </Grid>
                    {showProfInfo && (
                      <>
                        <Grid item sm={2}>
                          <Field
                            name="empresa"
                            component={Select}
                            label="Casa"
                            onChange={this.handleCasaChange}>
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            <MenuItem value={'SENADO'}>Senado</MenuItem>
                            <MenuItem value={'TCU'}>TCU</MenuItem>
                            <MenuItem value={'CAMARA'}>Câmara</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name={isFiliadoCamara ? 'ponto' : 'matricula'}
                            component={isFiliadoCamara ? InputWithPrefix : InputText}
                            label={isFiliadoCamara ? 'Ponto' : 'Matrícula'}
                            onChange={this.handleMatriculaChange}
                          />
                        </Grid>
                        <Grid item sm={7}>
                          <Cargo initialValues={initialValues} />
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="situacao_filiado" component={Select} label="Situação">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                            <MenuItem value={'APOSENTADO'}>Aposentado</MenuItem>
                            <MenuItem value={'PENSIONISTA'}>Pensionista</MenuItem>
                            {isFiliadoInativo ? (
                              <MenuItem value={'INATIVO'}>Inativo</MenuItem>
                            ) : null}
                          </Field>
                        </Grid>
                        <DadosPensionista />
                        <Grid item sm={3}>
                          <Field name="escolaridade" component={Select} label="Escolaridade">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.escolaridade.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item sm={4}>
                          <Field
                            name="filiado_associacao"
                            component={Select}
                            label="É filiado a alguma associação?">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            <MenuItem value={'CAMARA'}>Na Câmara</MenuItem>
                            <MenuItem value={'SENADO'}>No Senado</MenuItem>
                            <MenuItem value={'TCU'}>No TCU</MenuItem>
                            <MenuItem value={'OUTRO'}>Outro</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item sm={3}>
                          <Associacao />
                        </Grid>
                        <AssociacaoNaoList />
                        <Grid item sm={3}>
                          <Field name="forma_pagamento" component={Select} label="Forma de pagamento">
                            <MenuItem value="">
                              <em>Selecione</em>
                            </MenuItem>
                            {config.pagamentos.map((p) => (
                              <MenuItem key={p} value={p}>
                                {p}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item sm={2}>
                          <Field name="valor_pago" component={InputNumber} label="Valor pago" />
                        </Grid>
                        <Grid item sm={2}>
                          <Field
                            name="data_filiacao"
                            component={InputText}
                            {...dateMask}
                            label="Filiado em"
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <Field
                            name="observacao"
                            component={InputText}
                            label="Observação"
                            multiline={true}
                            minRows={3}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => this.toggleFichaCadastral()}
                        size="small">
                        <AssignmentInd color="primary" />
                        <Typography style={{ marginLeft: 8 }} color="primary">
                          Ficha Cadastral
                        </Typography>
                      </Button>
                    </Grid>
                    {showFichaCadastral && (
                      <Grid
                        item
                        sm={12}
                        style={{ marginLeft: 8, marginBottom: 8, display: 'flex' }}>
                        <AnexoFiliado
                          onChange={(anexosTemp) => {
                            this.setState(() => {
                              this.setState({ anexosTemp });
                              change('anexosFlag', new Date().getTime());
                            });
                          }}
                          filiadoId={filiadoId}
                          open={open}
                          anexo={filiado?.anexos}
                        />
                        <Field name="anexosFlag" component="input" type="hidden" />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => this.openDependents()}
                        size="small">
                        <Group color="primary" />
                        <Typography style={{ marginLeft: 8 }} color="primary">
                          Dependentes
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <HistoricoFiliado id={filiadoId} />
                </Grid>
              }
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={() => reset() & handleClose(null)} color="default">
              Cancelar
            </Button>
            <Button type="submit" disabled={!canEdit || pristine || submitting} color="primary">
              Salvar
            </Button>
          </DialogActions>
          <DependentList
            open={showDepentents}
            handleClose={this.closeDependents}
            canEdit={canEdit || isEditor}
          />
        </form>
      </Dialog>
    );
  }
}

const Cargo = formValues('empresa')(({ empresa, initialValues }) => {
  let cargos = [];
  if (empresa) {
    cargos = config.orgaoCargo[empresa];
  }

  if (initialValues === null) {
    cargos = config.orgaoCargo[empresa]?.filter((cargo) => cargo.ativo) || [];
  }
  return (
    <Field name="cargo" component={Select} label="Cargo">
      <MenuItem value="">
        <em>Selecione</em>
      </MenuItem>
      {cargos.map((cargo) => (
        <MenuItem key={cargo.nome} value={cargo.nome}>
          {cargo.nome}
        </MenuItem>
      ))}
    </Field>
  );
});

const Associacao = formValues('filiado_associacao')(({ filiado_associacao }) => {
  let associacao = [];
  if (filiado_associacao && config.associacoes[filiado_associacao]) {
    associacao = config.associacoes[filiado_associacao].sort((a, b) => a.localeCompare(b));
  }

  return (
    <>
      {filiado_associacao !== 'OUTRO' && (
        <Field name="associacao" component={Select} label="Associação">
          <MenuItem value="">
            <em>Selecione</em>
          </MenuItem>
          {associacao.map((associacao) => (
            <MenuItem key={associacao} value={associacao}>
              {associacao}
            </MenuItem>
          ))}
        </Field>
      )}
      {filiado_associacao === 'OUTRO' && (
        <Field name="associacao_outra" component={InputText} label="Especifique" />
      )}
    </>
  );
});

const AssociacaoNaoList = formValues('associacao')(({ associacao }) => {
  if (associacao === 'Outra') {
    return (
      <>
        <Grid item sm={4}>
          <Field name="associacao_nao_listada" component={InputText} label="Especifique" />
        </Grid>
      </>
    );
  }
  return null;
});

const DadosPensionista = formValues('situacao_filiado')(({ situacao_filiado }) => {
  if (situacao_filiado === 'PENSIONISTA') {
    return (
      <React.Fragment>
        <Grid item sm={7}>
          {/* nome_falecido é somente no db, o field correto é o que está na label */}
          <Field name="nome_falecido" component={InputText} label="Nome do servidor titular" />
        </Grid>
        <Grid item sm={2}>
          {/* cpf_falecido é somente no db, o field correto é o que está na label */}
          <Field name="cpf_falecido" component={InputText} label="CPF do titular" {...cpfMask} />
        </Grid>
      </React.Fragment>
    );
  }
  return null;
});

const SexoOutro = formValues('sexo')(({ sexo }) => {
  return sexo === 'Outro' ? (
    <Grid item>
      <Field name="sexo_outro" component={InputText} label="Especifique" />
    </Grid>
  ) : null;
});

const mapStateToProps = ({ filiado: { filiado, filiadoId } }) => ({
  filiado: filiado,
  filiadoId: filiadoId,
  initialValues: filiado,
});

UserDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getFiliado,
    saveFiliado,
    setFiliado,
    setFiliadoId,
    removerAnexoFiliado,
    incluirAnexoFiliado,
    criarLog
  })
)(
  reduxForm({
    form: 'filiado',
    validate,
    enableReinitialize: true,
  })(withSnackbar(UserDetail))
);
