import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { getFiliados } from '../../actions/filiado';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputMask from 'react-input-mask';
import Search from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import { logBySearchFiliado, formataCPF } from '../commons/util';
import { listDependentes } from '../../actions/dependent'

const Verificador = () => {
  const dispatch = useDispatch()

  const { dependentesComTitular } = useSelector((state) => state.dependent)
  const { filiados } = useSelector((state) => state.filiado)

  const [cpfSearch, setCpfSearch] = useState('');
  const [foundFiliado, setFoundFiliado] = useState(null);
  const [foundDependente, setFoundDependente] = useState(null)
  const [searchPerformed, setSearchPerformed] = useState(false);

  const dependentesList = Object.values(dependentesComTitular).filter((d) => d.cpf)
  const filiadosAtivos = filiados.filter(
    (filiado) => filiado.situacao_filiado !== 'INATIVO' && !filiado.data_desfiliacao
  );
  const filiadosDF = filiadosAtivos.filter((filiado) => filiado?.estado === 'DF');
  const filiadoInativo = foundFiliado?.situacao_filiado === 'INATIVO'
  const ninguemEncontrado = searchPerformed && !foundFiliado && !foundDependente && cpfSearch
  const nomeFiliado = foundFiliado?.nome_completo
  const casaFiliado = foundFiliado?.empresa
  const nomeDependente = foundDependente?.nome_completo
  const totalFiliados = filiadosAtivos?.length.toLocaleString('pt-BR')
  const totalFiliadosDF = filiadosDF?.length.toLocaleString('pt-BR')

  useEffect(() => {
    dispatch(getFiliados());
    dispatch(listDependentes())
  }, [dispatch]);


  const handleCpfChange = (event) => {
    const value = event.target.value;
    setCpfSearch(value);
    if (!value) {
      setFoundFiliado(null);
      setSearchPerformed(false);
    }
  };

  const handleSearch = () => {
    const value = cpfSearch.replace(/[^\d]/g, '');

    if (value) {
      const filiado = filiados.find(
        (item) => item.cpf && item.cpf.toString().replace(/[^\d]/g, '').includes(value)
      );

      const dependente = dependentesList.find(
        (item) => item.cpf && item.cpf.replace(/[^\d]/g, '').includes(value)
      )
      setFoundFiliado(filiado);
      setFoundDependente(dependente)
      setSearchPerformed(true);
      logBySearchFiliado(`Consultou o CPF ${formataCPF(value)}`);
    } else {
      setFoundFiliado(null);
      setFoundDependente(null)
      setSearchPerformed(false);
    }
  };

  return (
    <React.Fragment>
      <SindilegisBar />
      <Container maxWidth="xl" style={{ paddingTop: 20, margin: 'auto' }}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '100vh', backgroundColor: `#fefefe` }}>
          <Grid item xs={12}>
            <Grid style={{ textAlign: 'center', marginBottom: 20 }}>
              <Typography variant="h5">
                Insira o CPF da pessoa para verificar se ela está filiada ao Sindilegis.
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
              {foundFiliado && (
                <Paper elevation={3} style={{ padding: 20, textAlign: 'center', width: '50%' }}>
                  <Typography variant="h6">Filiado(a) encontrado(a)</Typography>
                  <Typography variant="body1">Nome: {nomeFiliado}</Typography>
                  <Typography variant="body1">Casa: {casaFiliado}</Typography>
                  {filiadoInativo && (
                    <Typography variant="body2" style={{ color: 'orange' }}>
                      FILIADO CONSTA COMO INATIVO. PROCURE O ATENDIMENTO DO SINDILEGIS PARA
                      VERIFICAR A SITUAÇÃO.
                    </Typography>
                  )}
                </Paper>
              )}
              {foundDependente && (
                <Paper elevation={3} style={{ padding: 20, textAlign: 'center', width: '50%' }}>
                  <Typography variant="h6">Dependente encontrado</Typography>
                  <Typography variant="body1">Nome: {nomeDependente}</Typography>
                </Paper>
              )}
              {ninguemEncontrado && (
                <Paper elevation={3} style={{ padding: 20, textAlign: 'center', width: '50%', color: 'red' }}>
                  <Typography variant="h6">CPF não consta como filiado ou dependente</Typography>
                </Paper>
              )}
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <InputMask mask="999.999.999-99" value={cpfSearch} onChange={handleCpfChange}>
                {() => (
                  <TextField
                    label="Pesquisar CPF"
                    variant="outlined"
                    style={{ width: '200px' }}
                  />
                )}
              </InputMask>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                style={{ marginLeft: 10 }}>
                <Search />
              </Button>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
              <Typography variant='h6'>Total de Filiados: {totalFiliados}</Typography>
              <Typography variant='h6'>Total de Filiados no DF: {totalFiliadosDF}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};


Verificador.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(Verificador);
