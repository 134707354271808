import {
  EXPENSES_SET,
  EXPENSE_SET,
  EXPENSE_ID_SET,
  EXPENSE_UPDATED,
  DELETE_EXPENSE,
  RECEITAS_SET,
  RECEITA_UPDATED,
  RECEITA_ID_SET,
  RECEITA_SET,
  DELETE_RECEITA,
  RECEITA_DESPESA_REQUEST,
  RECEITAS_E_DEPESAS_SET,
  HISTORICO_DESPESA_SET,
  EXPENSE_REQUEST,
  RECEITA_REQUEST,
} from './types';
import { db, functions } from '../firebase';
import { reset } from 'redux-form';
import _ from 'lodash';

export const parseDocx = (data) => {
  return (dispatch) => {
    functions.parseDocx(data).then((result) => {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${result.data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'recibo.docx';
      downloadLink.click();
    });
  };
};

export const liquidarPagamento = (id, liquidacao) => {
  return (dispatch) => {
    db.doUpdateExpense(id, liquidacao);
    const despesa = { id, ...liquidacao };
    dispatch({ type: EXPENSE_UPDATED, despesa });
  };
};

export const creditarReceita = (id, credito) => {
  return (dispatch) => {
    db.doUpdateReceita(id, credito);
    const receita = { id, ...credito };
    dispatch({ type: RECEITA_UPDATED, receita });
  };
};

export const incluirAnexoDespesa = (id, path) => {
  return (dispatch) => {
    db.doIncluirAnexoDepesa(id, path);
  };
};

export const incluirAnexoReceita = (id, path) => {
  return (dispatch) => {
    db.doIncluirAnexoReceita(id, path);
  };
};

export const atualizarDespesa = (id, despesa, callback) => {
  return (dispatch) => {
    db.doUpdateExpense(id, despesa).then(() => {
      dispatch({ type: EXPENSE_UPDATED, despesa: { id, ...despesa } });
      reset('expense');
      callback && callback();
    });
  };
};

export const atualizarReceita = (id, receita, callback) => {
  return (dispatch) => {
    db.doUpdateReceita(id, receita).then(() => {
      dispatch({ type: RECEITA_UPDATED, receita: { id, ...receita } });
      reset('receita');
      callback && callback();
    });
  };
};

export const getReceitasEDespesas = () => {
  return (dispatch) => {
    dispatch({ type: RECEITA_DESPESA_REQUEST });
    db.onceGetBeneficiarios().then((beneficiariosSnapshot) => {
      const beneficiarios = beneficiariosSnapshot.val();
      db.onceGetExpenses().then((snapshot) => {
        const expenses = converterDespesas(snapshot).filter((d) => !d.excluido);
        preencherBeneficiarios(expenses, beneficiarios);
        db.onceGetReceitas().then((receitasSnapshot) => {
          let receitas = receitasSnapshot.val();
          if (receitas) {
            Object.keys(receitas).map((id) => (receitas[id].id = id));
            receitas = _.values(receitas);
          } else {
            receitas = [];
          }
          dispatch({ type: RECEITAS_E_DEPESAS_SET, receitas, expenses });
        });
      });
    });
  };
};

export const getExpenses = (callback) => {
  return (dispatch) => {
    db.onceGetBeneficiarios().then((beneficiariosSnapshot) => {
      const beneficiarios = beneficiariosSnapshot.val();
      db.onceGetExpenses().then((snapshot) => {
        const expenses = converterDespesas(snapshot).filter((d) => !d.excluido);
        preencherBeneficiarios(expenses, beneficiarios);
        dispatch({ type: EXPENSES_SET, expenses });
        callback && callback(expenses);
      });
    });
  };
};

function preencherBeneficiarios(despesas, beneficiarios) {
  despesas.forEach((d) => {
    const beneficiario = beneficiarios[d.id_beneficiario];
    if (beneficiario) {
      d.nome_beneficiario = beneficiario.nome_beneficiario;
      d.cpfCnpj = beneficiario.cpfCnpj;
    }
  });
}

export const setExpenseId = (expenseId) => (dispatch) =>
  dispatch({ type: EXPENSE_ID_SET, expenseId });

export const setExpense = (expense) => (dispatch) => dispatch({ type: EXPENSE_SET, expense });

export const getExpense = (id) => {
  return (dispatch) => {
    dispatch({ type: EXPENSE_REQUEST, loadingExpense: true })
    db.onceGetExpense(id).then((snapshot) => {
      const expense = snapshot.val()
      dispatch({ type: EXPENSE_SET, expense })
    })
  }
}


export const deleteExpense = (id) => {
  return (dispatch) => {
    db.doDeleteExpense(id).then(() => {
      dispatch({ type: DELETE_EXPENSE, payload: { id } });
    });
  };
};

export const getReceitas = () => {
  return (dispatch) => {
    db.onceGetReceitas().then((snapshot) => {
      let receitas = snapshot.val();
      if (receitas) {
        Object.keys(receitas).map((id) => (receitas[id].id = id));
        receitas = _.values(receitas);
      } else {
        receitas = [];
      }
      dispatch({ type: RECEITAS_SET, receitas });
    });
  };
};

export const setReceitaId = (receitaId) => (dispatch) => {
  dispatch({ type: RECEITA_ID_SET, receitaId });
  dispatch(reset('receita'));
};

export const setReceita = (receita) => (dispatch) => dispatch({ type: RECEITA_SET, receita });

export const getReceita = (id) => (dispatch) => {
  dispatch({ type: RECEITA_REQUEST, loadingReceita: true })
  db.onceGetReceita(id).then((snapshot) => {
    const receita = snapshot.val();
    dispatch({ type: RECEITA_SET, receita });
  });
};

export const deleteReceita = (id) => {
  return (dispatch) => {
    db.doDeleteReceita(id).then(() => {
      dispatch({ type: DELETE_RECEITA, payload: { id } });
    });
  };
};

export const getHistoricoDespesa = (id) => {
  return (dispatch) => {
    db.onceGetHistoricoDespesa(id).then((snapshot) => {
      let historico = snapshot.val();
      if (historico) {
        Object.keys(historico).map((key) => (historico[key].data = parseInt(key)));
        historico ? (historico = _.values(historico).reverse()) : (historico = []);
        dispatch({ type: HISTORICO_DESPESA_SET, historico });
      }
    });
  };
};

export const setHistoricoDespesa = (historico) => (dispatch) =>
  dispatch({ type: HISTORICO_DESPESA_SET, historico });

function converterDespesas(snapshot) {
  let expenses = snapshot.val();
  if (expenses) {
    Object.keys(expenses).map((id) => (expenses[id].id = id));
    expenses = _.values(expenses).filter((d) => !!d.data_vencimento);
  } else {
    expenses = [];
  }
  return expenses;
}
