import React from 'react';
import XLSX from 'xlsx';
import { db } from '../../firebase';
import { differenceInYears } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import NumberFormat from 'react-number-format';

const dateFns = new DateFnsUtils({ locale: ptLocale });

export const formataData = (data) => {
  if (data) {
    try {
      return dateFns.format(new Date(data), 'dd/MM/yyyy');
    } catch (e) {
      console.error('Data inválida: ', data);
    }
  }
  return '';
};

export const formatarValor = (valor) => {
  return (
    <NumberFormat
      value={valor}
      displayType={'text'}
      isNumericString={true}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={true}
      fixedDecimalScale={true}
      prefix={'R$ '}
    />
  );
};

export function isDataAnterior(dt, dataAtual) {
  return (
    dt.getFullYear() < dataAtual.getFullYear() ||
    (dt.getFullYear() === dataAtual.getFullYear() && dt.getMonth() < dataAtual.getMonth()) ||
    (dt.getDate() < dataAtual.getDate() &&
      dt.getMonth() === dataAtual.getMonth() &&
      dt.getFullYear() === dataAtual.getFullYear())
  );
}

export const downloadXlsx = (columns, data, filename) => {
  const wb = XLSX.utils.book_new();
  const ws = getWorkSheet(columns, data);
  XLSX.utils.book_append_sheet(wb, ws);
  XLSX.writeFile(wb, filename);
  logUser(filename);
};

export const downloadWorksheets = (sheets, filename) => {
  const wb = XLSX.utils.book_new();
  sheets.forEach((item) => XLSX.utils.book_append_sheet(wb, item.ws, item.sheetName));
  XLSX.writeFile(wb, filename);
  logUser(filename);
};

export const readXlsx = (e) => {
  const workbook = XLSX.read(e.target.result, {
    type: 'buffer',
    cellDates: true,
  });
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json(sheet);
};

function logUser(filename) {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  db.doLogUser(authUser.uid, {
    type: 'report_download',
    filename,
    date: new Date().getTime(),
  });
}

export function logByUserLogin(data) {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  db.doLogUser(authUser.uid, {
    type: 'Login',
    data,
    date: new Date().getTime(),
  });
}

export function logBySearchFiliado(data) {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  db.doLogUser(authUser.uid, {
    type: 'Pesquisa por CPF',
    data,
    date: new Date().getTime(),
  });
}

export function getWorkSheet(columns, data) {
  const fields = columns.map((c) => c.field);
  const header = columns.map((c) => c.title);

  const array = [header];

  data.forEach((d) => {
    const values = fields.map((field) => d[field]);
    array.push(values);
  });
  return XLSX.utils.aoa_to_sheet(array);
}

function normalize(str) {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

function compare(a, b) {
  return a && b && normalize(a).indexOf(normalize(b)) > -1;
}

export const matchFullName = (term, full_name) => {
  term = term.trim();
  if (term && term.length > 2 && full_name) {
    let splitedTerm = term.split(' ');
    const splitedName = full_name.split(' ');
    if (splitedTerm.length > 1) {
      return (
        compare(splitedName[0], splitedTerm[0]) &&
        splitedName.some((p) => compare(p, splitedTerm[1])) &&
        (!splitedTerm[2] || splitedName.some((p) => compare(p, splitedTerm[2])))
      );
    } else {
      return splitedName.some((p) => compare(p, term));
    }
  }
  return false;
};

export const calcularIdade = (data_nascimento) => {
  if (data_nascimento) {
    try {
      const ar = data_nascimento.split('/');
      return differenceInYears(new Date(), new Date(ar[2], ar[1] - 1, ar[0]));
    } catch (err) {
      console.error('Data inválida', data_nascimento, err);
    }
  }
};

export const validateEmail = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email inválido'
    : undefined;
};

const labelMapping = {
  PROFILE: 'Página Inicial',
  DASHBOARD_FILIADOS: 'Dashboard Filiados',
  DASHBOARD_CASAS: 'Dashboard Casas',
  FINANCIAL: 'Financeiro',
  EXPENSES: 'Despesas',
  RELATORIO_DESPESAS: 'Relatório de Despesas',
  RECEITAS: 'Receitas',
  GRAFICOS_RECEITAS: 'Gráfico de Receitas',
  ITENS_DESPESA: 'Itens de Despesa',
  ACOMPANHAMENTO_ORCAMENTARIO: 'Acompanhamento Orçamentário',
  BENEFICIARIOS: 'Beneficiários',
  APROVADOR: 'Aprovador de Despesas',
  GRAFICOS: 'Gráficos',
  PROJECAO: 'Projeção',
  SUPRIMENTOS: 'Suprimentos',
  TRANSPARENCIA: 'Transparência',
  FILIADOS_RELATORIO: 'Relatório de Filiados',
  RELATORIO_DEPENDENTES: 'Relatório de Dependentes',
  EVENTOS: 'Eventos',
  INTERACOES: 'Interações',
  ITENS_INTERACAO: 'Intes de Interação',
  FUNCIONARIOS: 'Funcionários',
  ESCRITORIOS: 'Escritórios',
  ITENS_RECEITA: 'Itens de Receita',
  JURIDICO: 'Jurídico',
  JURIDICO_PROCESSOS: 'Gerenciar Processos',
  JURIDICO_PROCESSOS_RELATORIO: 'Relatório de Processos',
  OBJETO_PROCESSO: 'Gerenciar Objetos de Processo',
  COMUNICACAO: 'Comunicação',
  FILIADO: 'Filiado',
  FILIADOS: 'Filiados',
  POLITICO_SINDICAL: 'Sindical',
  AUTORIDADES: 'Perfis de Autoridades',
  GESTOR_CONTRATOS: 'Contratos e Documentos',
  PATRIMONIO: 'Patrimônio',
  PATRIMONIO_FLUXO: 'Controle de Fluxo',
  PATRIMONIO_HITORICO_MANUTENCAO: 'Histórico de Manutenção',
};

export const getRoutes = (routes, currentPath) => {
  const pathSegments = currentPath.split('/');
  const firstSegment = pathSegments[1];

  if (!firstSegment) {
    return [];
  }

  return Object.entries(routes)
    .filter(
      ([key, value]) =>
        value.startsWith(`/${firstSegment}`) && value !== currentPath && labelMapping[key]
    )
    .map(([key, value]) => ({
      label: labelMapping[key],
      route: value,
    }));
};

export const formataCPF = (cpf) => {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};
