import React from 'react';
import { Field } from 'redux-form';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '@material-ui/icons/Add';
import { Typography, TextField, Grid, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default (props) => {
  const { fields, beneficiarios, error, isContrato, disabled } = props;
  const color = error ? 'error' : 'inherit';
  const beneficiariosPj = beneficiarios.filter((beneficiario) => beneficiario.tipo === 'J');
  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          hidden={false}
          alignItems="flex-start"
          justifyContent="flex-start">
          <Grid item sm={8}>
            <Field
              name={`${item}.cnpj_contratado`}
              component={AutocompleteField}
              label={isContrato ? 'CNPJ' : 'CNPJ/CPF'}
              beneficiarios={isContrato ? beneficiariosPj : beneficiarios}
              disabled={disabled}
            />
          </Grid>
          <Grid item sm={1}>
            <IconButton
              onClick={() => fields.remove(index)}
              aria-label="Excluir"
              disabled={disabled}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <IconButton
        variant="insert"
        size="small"
        disabled={disabled}
        onClick={() => {
          fields.push();
        }}>
        <PlusIcon color={color} />{' '}
        <Typography variant="body2" color={color}>
          {isContrato ? 'CNPJ dos contratados' : 'CNPJ/CPF dos vinculados ao documento'}
        </Typography>
      </IconButton>
    </React.Fragment>
  );
};

const AutocompleteField = ({ input, label, beneficiarios, meta, ...custom }) => {
  return (
    <Autocomplete
      {...input}
      {...custom}
      options={beneficiarios}
      getOptionLabel={(option) => option.cpfCnpj}
      onChange={(event, newValue) => input.onChange(newValue ? newValue.cpfCnpj : '')}
      renderInput={(params) => (
        <TextField {...params} label={label} error={meta.touched && meta.error} />
      )}
      disableClearable
      renderOption={(option) => (
        <>
          <Typography variant="body2">{option.cpfCnpj}</Typography>
        </>
      )}
      value={beneficiarios.find((option) => option.cpfCnpj === input.value) || null}
      noOptionsText="Nenhum CNPJ encontrado"
    />
  );
};
