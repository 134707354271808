import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import sessionState from './session';
import userState from './user';
import dependent from './dependent';
import financial from './financial';
import role from './role';
import filiado from './filiado';
import beneficiario from './beneficiario';
import documento from './documento';
import item_despesa from './item_despesa';
import evento from './evento';
import funcionario from './funcionario';
import interacao from './interacao';
import suprimento from './suprimento';
import item_receita from './item_receita';
import item_interacao from './item_interacao';
import juridico from './juridico';
import comunicacao from './comunicacao';
import politico_sindical from './politico_sindical';
import objetoProcesso from './objetoProcesso';
import filiados_processos from './filiados_processos';
import documento_transparencia from './documento_transparencia';
import autoridade_politica from './autoridade_politica';
import consignacao from './consignacoes';
import contratos from './contratos';
import patrimonio from './patrimonio';

export default combineReducers({
  sessionState,
  userState,
  form,
  dependent,
  financial,
  role,
  filiado,
  beneficiario,
  documento,
  item_despesa,
  evento,
  funcionario,
  interacao,
  suprimento,
  item_receita,
  item_interacao,
  juridico,
  comunicacao,
  politico_sindical,
  objetoProcesso,
  filiados_processos,
  documento_transparencia,
  autoridade_politica,
  consignacao,
  contratos,
  patrimonio
});
