import React, { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, Field, formValues, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styles from './styles';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import DatePicker from '../commons/DatePicker';
import {
  criarBemPatrimonio,
  saveBemPatrimonio,
  getBem,
  setBemId,
  setBem,
} from '../../actions/patrimonio';
import Loading from '../commons/Loading';
import { getFuncionarios } from '../../actions/funcionario';
import { statusBem } from './common/config';
import InputInteger from '../commons/InputInteger';
import validate from './common/validate';
import AnexoBemPatrimonial from './anexos/anexoBemPatrimonial';

const BemPatrimonialForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  open,
  handleClose,
  enqueueSnackbar,
  by,
  initialValues,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const { bemId, loadingBem, bem } = useSelector((state) => state.patrimonio);
  const { funcionarios } = useSelector((state) => state.funcionario);
  const orderedFuncionarios = funcionarios.sort((a, b) => a.nome.localeCompare(b.nome));
  const title = isCreating ? 'Cadastrar Bem Patrimonial' : 'Editar Bem Patrimonial';
  const [anexosTemp, setAnexosTemp] = useState([]);
  const [openAnexos, setOpenAnexos] = useState(false);

  useEffect(() => {
    if (isCreating) {
      reset();
    }
  }, [isCreating, reset]);

  useEffect(() => {
    if (bemId && isCreating === false) {
      dispatch(getBem(bemId));
    }
    dispatch(getFuncionarios());
  }, [dispatch, bemId, isCreating]);

  const save = useCallback(
    async (bem) => {
      const clearValues = () => {
        dispatch(setBemId(null));
        dispatch(setBem(null));
      };
      bem = { ...bem, by };
      try {
        if (bemId) {
          await dispatch(
            saveBemPatrimonio(bemId, bem, () => {
              clearValues();
              enqueueSnackbar(`Edição salva com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        } else {
          dispatch(
            criarBemPatrimonio(bem, (key) => {
              dispatch(setBem(bem));
              dispatch(setBemId(key));
              enqueueSnackbar(`Bem patrimonial salvo com sucesso!`, { variant: 'success' });
            })
          );
        }
      } catch (error) {
        console.error('Erro ao salvar bem patrimonial:', error);
      }
    },
    [dispatch, bemId, by, enqueueSnackbar, handleClose]
  );

  const handleCancel = () => {
    reset();
    handleClose(null);
  };

  const handleAnexosChange = (anexosTemp) => {
    setAnexosTemp(anexosTemp);
    change('anexosFlag', new Date().getTime());
  };

  const handleCloseAnexos = () => {
    setOpenAnexos(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title">
      <Loading loading={loadingBem} />
      <form onSubmit={handleSubmit(save)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {open && (
          <DialogContent style={{ minHeight: '50vh' }}>
            {!loadingBem && (
              <Grid container spacing={4} style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Field name="tipo_objeto" component={InputText} label="Tipo de objeto" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="usuario" component={Select} label="Nome do Usuário">
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {orderedFuncionarios.map((funcionario) => (
                        <MenuItem key={funcionario.id} value={funcionario.nome}>
                          {funcionario.nome.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="n_patrimonio" component={InputInteger} label="Nº do Patrimônio" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="setor_alocado" component={InputText} label="Setor Alocado" />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="data_alocacao" component={DatePicker} label="Data de Alocação" />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="status" component={Select} label="Status">
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {statusBem.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <BemDescartado />
                  <Grid item sm={12}>
                    <Field name="n_serie" component={InputText} label="Nº de Série" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="descricao_bem" component={InputText} label="Descrição" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field name="especificacao" component={InputText} label="Especificação" />
                  </Grid>
                  {bem && (
                    <Grid item sm={12}>
                      <AnexoBemPatrimonial
                        onChange={handleAnexosChange}
                        open={openAnexos}
                        bemId={bemId}
                        anexo={bem?.anexos}
                        handleClose={handleCloseAnexos}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => handleCancel()} color="default">
            Cancelar
          </Button>
          <Button type="submit" disabled={pristine || submitting} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const BemDescartado = formValues('status')(({ status }) => {
  if (status === 'Descartado') {
    return (
      <Grid item sm={6}>
        <Field name="data_descarte" component={DatePicker} label="Data de Descarte" />
      </Grid>
    );
  }
  return null;
});

const mapStateToProps = (state) => {
  const { bem } = state.patrimonio;
  return {
    initialValues: bem,
  };
};

BemPatrimonialForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {})
)(
  reduxForm({
    form: 'bemPatrimonial',
    validate,
    enableReinitialize: true,
  })(withSnackbar(BemPatrimonialForm))
);
