import React, { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import InputText from '../../commons/InputText';
import Select from '../../commons/Select';
import DatePicker from '../../commons/DatePicker';
import {
  criarHistManutencao,
  saveHistManutencao,
  getHistManutencao,
  setHistManutencaoId,
  setHistManutencao,
} from '../../../actions/patrimonio';
import Loading from '../../commons/Loading';
import validate from '../common/validate';
import { getBensPatrimonio } from '../../../actions/patrimonio';
import { getFuncionarios } from '../../../actions/funcionario';

const HistManutencaoForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  open,
  change,
  handleClose,
  enqueueSnackbar,
  by,
  initialValues,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const { loadingHistManutencao, histManutencaoId } = useSelector((state) => state.patrimonio);
  const [tipoOjeto, setTipoObjeto] = useState('');
  const { bens } = useSelector((state) => state.patrimonio);
  const { funcionarios } = useSelector((state) => state.funcionario);
  const orderedFuncionarios = funcionarios.sort((a, b) => a.nome.localeCompare(b.nome));
  const title = isCreating ? 'Cadastrar Manutenção' : 'Editar Manutenção';

  useEffect(() => {
    if (isCreating) {
      reset();
    }
  }, [isCreating, reset]);

  useEffect(() => {
    if (histManutencaoId && isCreating === false) {
      dispatch(getHistManutencao(histManutencaoId));
    }
    dispatch(getBensPatrimonio());
    dispatch(getFuncionarios());
  }, [dispatch, histManutencaoId, isCreating]);

  const save = useCallback(
    async (histManutencao) => {
      const clearValues = () => {
        dispatch(setHistManutencaoId(null));
        dispatch(setHistManutencao(null));
      };
      histManutencao = { ...histManutencao, by };
      try {
        if (histManutencaoId) {
          await dispatch(
            saveHistManutencao(histManutencaoId, histManutencao, () => {
              clearValues();
              enqueueSnackbar(`Edição salva com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        } else {
          dispatch(
            criarHistManutencao(histManutencao, () => {
              clearValues();
              enqueueSnackbar(`Histórico de manutenção salvo com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        }
      } catch (error) {
        console.error('Erro ao salvar histórico de manutenção:', error);
      }
    },
    [dispatch, histManutencaoId, by, enqueueSnackbar, handleClose]
  );

  const handleCancel = () => {
    reset();
    handleClose(null);
  };

  const handleBemRelacionadoChange = (event) => {
    const bemSelecionado = Object.values(bens).find(
      (bem) => bem.n_patrimonio === event.target.value
    );
    if (bemSelecionado) {
      setTipoObjeto(bemSelecionado.tipo_objeto);
      change('tipo_objeto', bemSelecionado.tipo_objeto);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="form-dialog-title">
      <Loading loading={loadingHistManutencao} />
      <form onSubmit={handleSubmit(save)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {open && (
          <DialogContent style={{ minHeight: '50vh' }}>
            {!loadingHistManutencao && (
              <Grid container spacing={4} style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Field
                      name="bem_relacionado"
                      component={Select}
                      label="Nº de Patrimônio"
                      onChange={handleBemRelacionadoChange}>
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {Object.values(bens).map((bem) => (
                        <MenuItem key={bem.id} value={bem.n_patrimonio}>
                          {bem.n_patrimonio}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Field name="responsavel" component={Select} label="Responsável pela Manutenção">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {orderedFuncionarios.map((funcionario) => (
                      <MenuItem key={funcionario.id} value={funcionario.nome}>
                        {funcionario.nome.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Field>
                  <Grid item sm={12}>
                    <Field
                      name="descricao_problema"
                      component={InputText}
                      label="Descrição do Problema"
                      multiline={true}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field
                      name="data_manutencao"
                      component={DatePicker}
                      label="Data de Manutenção"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="data_retorno" component={DatePicker} label="Data de Retorno" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      name="tipo_objeto"
                      component={Select}
                      label="Tipo de Objeto"
                      value={tipoOjeto}
                      disabled
                      style={{ display: 'none' }}>
                      <MenuItem value={tipoOjeto}>{tipoOjeto}</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => handleCancel()} color="default">
            Cancelar
          </Button>
          <Button type="submit" disabled={pristine || submitting} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { histManutencao } = state.patrimonio;
  return {
    initialValues: histManutencao,
  };
};

HistManutencaoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    criarHistManutencao,
    saveHistManutencao,
    getHistManutencao,
  })
)(
  reduxForm({
    form: 'histManutencaoForm',
    validate,
    enableReinitialize: true,
  })(withSnackbar(HistManutencaoForm))
);
