export default (values) => {
    const errors = {}
    const requiredFields = ["tipo_objeto", "nome", "data_inicio", "data_fim", "data_alerta"
    ]
    requiredFields.forEach((field) => {
        if (!values[field] || values[field].length === 0) {
            errors[field] = 'Obrigatório'
        }
    })
    return errors
}