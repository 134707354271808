import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { storage } from '../../../firebase';
import tableI18n from '../../TableLocalization';
import styles from './styles';
import { connect, useDispatch } from 'react-redux';
import { getContrato } from '../../../actions/gestao_contratos';

const ArquivosDocumentoContrato = ({
  handleClose,
  classes,
  open,
  contratoId,
  handleDeleteArquivoContrato,
  contrato,
  disabled,
}) => {
  const [anexos, setAnexos] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && contratoId) {
      dispatch(getContrato(contratoId));
    }
  }, [open, contratoId, dispatch]);

  const loadAnexos = useCallback(() => {
    if (contrato && contrato.anexos) {
      setAnexos(Object.values(contrato.anexos));
    }
  }, [contrato]);

  useEffect(() => {
    loadAnexos();
  }, [contrato, loadAnexos, contratoId]);

  const handleDelete = (item) => {
    const { id, url } = item;
    handleDeleteArquivoContrato(id, contratoId, url)
      .then(() => {
        setAnexos((prevAnexos) => prevAnexos.filter((anexo) => anexo.id !== id));
      })
      .catch((error) => {
        console.error('Erro ao excluir arquivo:', error);
      });
  };

  const titulo = contrato?.numero_contrato;
  const anexosValidos = anexos.filter((anexo) => anexo.situacao === 'Ativo');
  const disabledDelte = anexosValidos.length === 1;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        <div className={classes.demo}>
          <MaterialTable
            columns={[
              {
                title: '',
                field: 'nome',
                render: (rowData) => (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => storage.downloadAnexo(rowData.url)}>
                    <Grid container alignItems="center">
                      {rowData.nome}
                    </Grid>
                  </Link>
                ),
              },
            ]}
            data={anexosValidos}
            title={'Arquivos'}
            options={{
              actionsColumnIndex: -1,
              sorting: false,
            }}
            actions={
              !disabled
                ? [
                    {
                      icon: 'delete',
                      tooltip: disabledDelte
                        ? `É necessário que tenha pelo menos um anexo relacionado ao ${contrato?.tipo_objeto.toLowerCase()}`
                        : 'Excluir',
                      onClick: (event, row) => handleDelete(row),
                      disabled: disabledDelte,
                    },
                  ]
                : []
            }
            localization={tableI18n}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ contratos }) => ({
  contrato: contratos.contrato,
  contratoId: contratos.contratoId,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getContrato })(ArquivosDocumentoContrato)
);
