import {
  CONTRATOS_SET,
  CONTRATO_SET,
  CONTRATO_ID_SET,
  CONTRATOS_REQUEST,
  CONTRATO_UPDATED,
  DELETE_CONTRATO,
  HISTORICO_SET
} from '../actions/types';

const INITIAL_STATE = {
  contratos: [],
  contrato: null,
  contratoId: null,
  historico: [],
  loading: false,
};

function contratoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTRATOS_SET: {
      return { ...state, contratos: action.contratos, loading: false };
    }
    case CONTRATOS_REQUEST: {
      return { ...state, loading: true };
    }
    case CONTRATO_SET: {
      return { ...state, contrato: action.contrato, loading: false };
    }
    case CONTRATO_ID_SET: {
      return { ...state, contratoId: action.contratoId };
    }
    case HISTORICO_SET: {
      return { ...state, historico: action.historico }
    }
    case CONTRATO_UPDATED: {
      const { contratos } = state;
      const { id, data } = action.payload;
      const index = contratos.findIndex((f) => f.id === id);
      const contrato = { ...contratos[index], ...data };
      contratos.splice(index, 1, contrato);
      return { ...state, contratos: [...contratos] };
    }
    case DELETE_CONTRATO: {
      const { contratos } = state;
      const index = contratos.findIndex((f) => f.id === action.payload.id);
      contratos.splice(index, 1);
      return { ...state, contratos: [...contratos] };
    }
    default:
      return state;
  }
}

export default contratoReducer;
