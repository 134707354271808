import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styles from './../styles';
import InputText from '../../commons/InputText';
import Select from '../../commons/Select';
import DatePicker from '../../commons/DatePicker';
import { criarFluxo, saveFluxo, getFluxo, setFluxoId, setFluxo } from '../../../actions/patrimonio';
import Loading from '../../commons/Loading';
import validate from './../common/validate';
import { getBensPatrimonio } from '../../../actions/patrimonio';
import { getFuncionarios } from '../../../actions/funcionario';
import TimePicker from '../../commons/TimePicker';

const FluxoForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  open,
  handleClose,
  enqueueSnackbar,
  by,
  initialValues,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const { loadingFluxo, fluxoId } = useSelector((state) => state.patrimonio);
  const { bens } = useSelector((state) => state.patrimonio);
  const { funcionarios } = useSelector((state) => state.funcionario);
  const orderedFuncionarios = funcionarios.sort((a, b) => a.nome.localeCompare(b.nome));
  const title = isCreating ? 'Cadastrar Saída' : 'Editar Saída';

  useEffect(() => {
    if (isCreating) {
      reset();
    }
  }, [isCreating, reset]);

  useEffect(() => {
    if (fluxoId && isCreating === false) {
      dispatch(getFluxo(fluxoId));
    }
    dispatch(getBensPatrimonio());
    dispatch(getFuncionarios());
  }, [dispatch, fluxoId, isCreating]);

  const save = useCallback(
    async (fluxo) => {
      fluxo = { ...fluxo, by };
      const clearValues = () => {
        dispatch(setFluxoId());
        dispatch(setFluxo());
      };
      try {
        if (fluxoId) {
          await dispatch(
            saveFluxo(fluxoId, fluxo, () => {
              clearValues();
              enqueueSnackbar(`Edição salva com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        } else {
          dispatch(
            criarFluxo(fluxo, () => {
              clearValues();
              enqueueSnackbar(`Fluxo salvo com sucesso!`, { variant: 'success' });
              handleClose();
            })
          );
        }
      } catch (error) {
        console.error('Erro ao salvar fluxo:', error);
      }
    },
    [dispatch, fluxoId, by, enqueueSnackbar, handleClose]
  );

  const handleCancel = () => {
    reset();
    handleClose(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="form-dialog-title">
      <Loading loading={loadingFluxo} />
      <form onSubmit={handleSubmit(save)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {open && (
          <DialogContent style={{ minHeight: '50vh' }}>
            {!loadingFluxo && (
              <Grid container spacing={4} style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Field name="bem_relacionado" component={Select} label="Nº de Patrimônio">
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {Object.values(bens).map((bem) => (
                        <MenuItem key={bem.id} value={bem.n_patrimonio}>
                          {bem.n_patrimonio}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Field name="responsavel" component={Select} label="Nome do Responsável">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {orderedFuncionarios.map((funcionario) => (
                      <MenuItem key={funcionario.id} value={funcionario.nome}>
                        {funcionario.nome.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Field>
                  <Grid item sm={12}>
                    <Field
                      name="motivo_movimentacao"
                      component={InputText}
                      label="Motivo da Movimentação"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="data_saida" component={DatePicker} label="Data de Saída" />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="hora_saida" component={TimePicker} label="Hora de Saída" />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="data_retorno" component={DatePicker} label="Data de Retorno" />
                  </Grid>
                  <Grid item sm={6}>
                    <Field name="hora_retorno" component={TimePicker} label="Hora de Retorno" />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      name="estado_patrimonio"
                      component={InputText}
                      label="Estado de Retorno"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => handleCancel()} color="default">
            Cancelar
          </Button>
          <Button type="submit" disabled={pristine || submitting} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { fluxo } = state.patrimonio;
  return {
    initialValues: fluxo,
  };
};

FluxoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    criarFluxo,
    saveFluxo,
    getFluxo,
  })
)(
  reduxForm({
    form: 'fluxoForm',
    validate,
    enableReinitialize: true,
  })(withSnackbar(FluxoForm))
);
