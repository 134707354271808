import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getHistoricoDespesa, setHistoricoDespesa } from '../../actions/financial';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { formataData } from './util';
import styles from './styles';

const useStyles = makeStyles(styles);

const HistoricoDespesa = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const historico = useSelector((state) => state.financial.historico);

  useEffect(() => {
    if (id) {
      dispatch(getHistoricoDespesa(id));
    }
    return () => {
      dispatch(setHistoricoDespesa(null));
    };
  }, [dispatch, id]);

  if (_.isEmpty(historico)) {
    return null;
  }

  return (
    <Accordion style={{ width: '100%', marginTop: '30px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Histórico</Typography>
      </AccordionSummary>
      <AccordionDetails>{renderItem(historico)}</AccordionDetails>
    </Accordion>
  );
};

const renderItem = (historico) => {
  return (
    <Grid container item spacing={2} sm={12}>
      <Toolbar>
        <Typography variant="h6" id="tableTitle">
          Histórico de alterações
        </Typography>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Alteração</TableCell>
            <TableCell>Responsável</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historico.map((row) => (
            <TableRow key={row.data}>
              <TableCell>{formataData(row.data)}</TableCell>
              {row.diff ? (
                <TableCell>
                  {Object.keys(row.diff).map((key) => renderCell(key, row))}
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
              <TableCell>{row.nome_responsavel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

const renderCell = (key, row) => {
  let value = row.diff[key];

  if (key === 'anexos') {
    value = _.values(value)
      .map((v) => v.nome)
      .join(', ');
  }

  return <Typography variant="body2" key={key}>{`${key}: ${value}`}</Typography>;
};

export default HistoricoDespesa;
